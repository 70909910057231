#BlogSection {
  height: 60vh;
  position: relative;
}

#recentArticles {
  max-width: 80%;
  margin: 0 auto;
}

#allArticles {
  max-width: 80%;
  margin: 0 auto;
}

.arrondiIn {
  border-radius: 60px;
  border: 1px solid var(--gray-200, #e4e4e7);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(7px);
  color: #000000;
  padding: 2px 20px 2px;
  display: inline-table;
}

.inputWrapper {
  position: relative;
  display: initial;
  /* display: flex;  */
}

.inputSearch {
  background-color: white;
  font-family: "Plus Jakarta Sans";
  text-align: left;
  border-radius: 77px;
  padding-left: 3%;
  font-size: 20px;
  color: #52525b;
  height: 56px;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  z-index: 2;
  /* flex-grow: 1; */
}

.inputSearch::placeholder {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.shadown {
  background: linear-gradient(
    90deg,
    #44bcff 0%,
    #44b0ff 23.44%,
    #ff44ec 48.96%,
    #ff44ec 73.96%,
    #ff675e 100%
  );
  filter: blur(22.5px);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.shadownVign {
  background: linear-gradient(
    90deg,
    #44bcff 0%,
    #44b0ff 23.44%,
    #ff44ec 48.96%,
    #ff44ec 73.96%,
    #ff675e 100%
  );
  filter: blur(30px);
  position: absolute;
  z-index: -1;
  top: 15%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 10px;
}

.SocialLinkedin {
  background-color: #0e76a8;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialReddit {
  background-color: #fe4500;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialPinterest {
  background-color: #e60023;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialTwitter {
  background-color: #00acee;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialInstagram {
  background-color: #000000;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialFacebook {
  background-color: #3b5998;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialTiktok {
  background-color: #000000;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.SocialYoutube {
  background-color: #ff0000;
  width: 270px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.youtube {
  color: #ff0000;
}

.linkedin {
  color: #0e76a8;
}

.medium {
  color: black;
}

.twitter {
  color: #00acee;
}

.facebook {
  color: #3b5998;
}

.g2 {
  color: #ff4a2c;
  max-width: 25%;
}

.ph {
  color: #da552f;
}

.reddit {
  color: #fe4500;
}

.google {
  color: #184dc5;
  max-width: 15%;
}

.twitter {
  max-width: 10%;
}

.pinterest {
  color: #e60023;
}

.instagram {
  color: black;
}

.backWrapper {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.instagramBack {
  position: absolute;
  top: 0;
  transform: translateX(20%) rotate(10deg);
  right: 0;
  width: 120px;
  height: auto;
  /* filter: blur(0.5px); */
}

.slackBack {
  position: absolute;
  top: 180px;
  left: 0;
  transform: translateX(-30%) rotate(10deg);
  width: 140px;
  height: auto;
  filter: blur(3px);
}

.slackStyleBack {
  position: absolute;
  top: 100px;
  filter: blur(1px);
  right: 30%;
  transform: translateX(20%) rotate(-10deg);
  width: 400px;
  height: auto;
}

.linkedinBack {
  position: absolute;
  /* filter: blur(1px); */
  top: 0px;
  /* right: 30%; */
  transform: translateX(10%) rotate(-30deg);
  width: 100px;
  height: auto;
}

.redditBack {
  position: absolute;
  width: 120px;
  height: auto;
  left: 30%;
  bottom: 100px;
  /* filter: blur(0.5px); */
  transform: translateX(40%) rotate(30deg);
}

.paragraphHome {
  text-align: left;
  width: 55%;
}

.phoneSection {
  width: 340px;
}

.pinterestBack {
  position: absolute;
  bottom: 120px;
  right: 17%;
  transform: translateX(20%) rotate(18deg);
  width: 100px;
  height: auto;
  /* filter: blur(0.5px); */
}

.youtubeBack {
  position: absolute;
  top: 110px;
  right: 0;
  /* filter: blur(0.5px); */
  width: 120px;
  height: auto;
}

.referal1Back {
  /* filter: blur(1.0px); */
  position: absolute;
  bottom: 50px;
  left: 8%;
  width: 300px;
  height: auto;
}

.referalBack {
  position: absolute;
  /* filter: blur(0.5px); */
  top: 180px;
  left: 25%;
  /* transform: translateX(-50%); */
  width: 230px;
  height: auto;
}

.ctaSignup {
  padding: 10px 20px;
  border-radius: 46px;
  background: var(
    --Gradient,
    linear-gradient(130deg, #ffd788 28.79%, #ffbfbf 64.77%, #dda5ff 100%)
  );
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

.SocialsVign {
  background-color: #fff;
  text-align: left;
  padding: 20px;
  width: 300px;
  color: black;
  height: 330px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
  display: grid;
  border: 1px solid var(--gray-100, #f2f2f2);

  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
}

.SocialsVignSecond {
  background-color: #fff;
  text-align: left;
  padding: 20px;
  filter: blur(3px);
  width: 300px;
  color: black;
  height: 200px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 50px;
  position: relative;
  z-index: 9;
  box-shadow: 2px 2px 4px rgba(196, 196, 196, 0.2);
}

.alignVignHome {
  display: flex;
  /* padding: 16px 24px; */
  padding: 20px 0px 0px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  flex-basis: calc(25% - 16px);
  max-width: calc(25% - 16px);
  box-sizing: border-box;
  min-height: 100%; /* Assurez-vous que chaque vignette prend toute la hauteur disponible */
  position: relative; /* Cela permettra de positionner le triangle par rapport à la vignette */
}

.webkitBox.secondRow .alignVignHome {
  position: relative;
  overflow: hidden;
}

.webkitBox.secondRow .alignVignHome::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Appliquer le masque au bas de 100% de la vignette */
  background: linear-gradient(
    to top,
    #fafafa,
    rgba(255, 255, 255, 0)
  ); /* Ajuster l'opacité ici */
  filter: blur(6px); /* Ajuster l'intensité du flou si nécessaire */
  z-index: 999;
}

.webkitBox.thirdRow .alignVignHome {
  position: relative;
  overflow: hidden;
}

.webkitBox.thirdRow .alignVignHome::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Appliquer le masque au bas de 100% de la vignette */
  background: linear-gradient(
    to top,
    #fafafa,
    rgba(255, 255, 255, 0.7)
  ); /* Ajuster l'opacité ici */
  filter: blur(8px); /* Ajuster l'intensité du flou si nécessaire */
  z-index: 999;
}

.text-truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;
}

.timeAgo {
  color: #8a8a8a;
  font-family: "Plus Jakarta Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.SocialSoon {
  background: #fefefe;
  width: 150px;
  color: white;
  height: 150px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.lineHeight {
  line-height: 15px;
}

.NameChaine {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.webkitBox {
  display: inline-flex;
}

.webkitBox p {
  color: #000000;
}

.descriptifPrice {
  white-space: normal;
  text-align: center;
  color: var(--gray-300, #666);
  text-align: center;

  /* H4 */
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 167.857% */
}

.hrborder {
  border: 1px solid #e9e9e9;
}

.notificationBadge {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: #000;
  color: white;
  width: 35px;
  height: 35px;
  padding: 20px;
  font-weight: 500;
  z-index: 999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: -19px 20px 11px -17px #686767; */
  font-size: 14px;
}

.productHuntButton {
  vertical-align: -webkit-baseline-middle;
  margin-right: 30px;
}

#BlogSection h1,
#BlogSection p {
  /* max-width: 860px; */
  justify-items: center;
}

#BlogSection p {
  margin-bottom: 40px;
  color: white;
}

#BlogSection h5 {
  color: #000;
}

.notationRanking {
  color: #ead900;
}

.avatarRanking {
  margin-top: 15px;
  border-radius: 20px;
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  vertical-align: bottom;
  margin-left: 20px;
  margin-right: 10px;
}

.avisDisplay {
  margin-top: 30px;
  max-width: 60%;
  font-size: 14px;
  display: inline-table;
  background-color: rgba(255, 255, 255, 0.747);
  border-radius: 10px;
  padding: 20px;
  color: black;
}

.noNeedCard {
  color: #858587;
  margin-left: 300px;
  margin-top: 5px;
  font-size: 14px;
}

.line {
  max-width: 20%;
  position: absolute;
  left: 20%;
  top: 55%;
}

#Screen h5 {
  font-family: Inter, Helvetica Neue, system-ui, -apple-system;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#Screen h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  position: relative;
  padding-left: 0px;
}

#Screen {
  margin-top: 200px;
  position: relative;
  overflow: hidden;
}

#Screen .bgContainer {
  position: absolute;
  top: 200px;
  left: 0;
  z-index: -1;
  height: 1748px;
  width: 2398px;
  overflow: hidden;
}
#Screen .bgContainer img {
  width: 2398px;
  height: 1748px;
  max-width: 2398px;
}

#Screen .wrap ul {
  display: flex;
  flex-direction: column;
}

#Screen .wrap ul li {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 160px;
}

#Screen .wrap ul li .content {
  max-width: 60%;
  display: inline-block;
  padding: 15px;
  vertical-align: middle;
}

#Screen .wrap ul li img {
  max-width: 60%;
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
}

#Screen .wrap ul li:nth-child(1) img {
  background-image: radial-gradient(
      circle farthest-corner at 125% 100%,
      #fff 19%,
      hsla(0, 0%, 100%, 0) 41%
    ),
    linear-gradient(38deg, hsla(0, 0%, 100%, 0) 78%, hsla(0, 0%, 100%, 0.69)),
    linear-gradient(45deg, #fff 10%, hsla(0, 0%, 100%, 0) 22%),
    linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0) 91%),
    radial-gradient(
      circle farthest-corner at 0 -50%,
      hsla(0, 0%, 100%, 0) 15%,
      rgba(112, 207, 255, 0.59) 38%,
      hsla(0, 0%, 100%, 0) 65%
    ),
    repeating-linear-gradient(
      214deg,
      hsla(0, 0%, 100%, 0),
      rgba(97, 76, 255, 0.3) 25%,
      hsla(0, 0%, 100%, 0) 71%
    ),
    repeating-linear-gradient(
      212deg,
      hsla(0, 0%, 100%, 0) 51%,
      rgba(124, 20, 252, 0.23) 64%,
      hsla(0, 0%, 100%, 0) 80%
    );
}

#Screen .wrap ul li:nth-child(2) img {
  background-image: radial-gradient(
      circle farthest-corner at 125% 100%,
      #fff 19%,
      hsla(0, 0%, 100%, 0) 41%
    ),
    linear-gradient(38deg, hsla(0, 0%, 100%, 0) 78%, hsla(0, 0%, 100%, 0.69)),
    linear-gradient(45deg, #fff 10%, hsla(0, 0%, 100%, 0) 22%),
    linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0) 91%),
    radial-gradient(
      circle farthest-corner at 0 -50%,
      hsla(0, 0%, 100%, 0) 15%,
      rgba(255, 195, 246, 0.4) 38%,
      hsla(0, 0%, 100%, 0) 65%
    ),
    repeating-linear-gradient(
      214deg,
      hsla(0, 0%, 100%, 0),
      rgba(80, 0, 241, 0.3) 25%,
      hsla(0, 0%, 100%, 0) 71%
    ),
    repeating-linear-gradient(
      212deg,
      hsla(0, 0%, 100%, 0) 51%,
      rgba(251, 74, 186, 0.334) 64%,
      hsla(0, 0%, 100%, 0) 80%
    );
}

#Screen .wrap ul li:nth-child(3) img {
  background-image: radial-gradient(
      circle farthest-corner at 125% 100%,
      #fff 19%,
      hsla(0, 0%, 100%, 0) 41%
    ),
    linear-gradient(38deg, hsla(0, 0%, 100%, 0) 78%, hsla(0, 0%, 100%, 0.69)),
    linear-gradient(45deg, #fff 10%, hsla(0, 0%, 100%, 0) 22%),
    linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0) 91%),
    radial-gradient(
      circle farthest-corner at 0 -50%,
      hsla(0, 0%, 100%, 0) 15%,
      rgba(249, 167, 26, 0.4) 38%,
      hsla(0, 0%, 100%, 0) 65%
    ),
    repeating-linear-gradient(
      214deg,
      hsla(0, 0%, 100%, 0),
      rgba(255, 52, 143, 0.3) 25%,
      hsla(0, 0%, 100%, 0) 71%
    ),
    repeating-linear-gradient(
      212deg,
      hsla(0, 0%, 100%, 0) 51%,
      rgba(255, 209, 27, 0.2) 64%,
      hsla(0, 0%, 100%, 0) 80%
    );
}

#Screen .wrap ul li h4 {
  max-width: 510px;
  font-size: 44px;
  font-weight: 570;
  letter-spacing: 0;
  line-height: 1.1;
  margin-bottom: 20px;
}

#Screen .wrap ul li p {
  max-width: 500px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8;
}

.vectorHeroADA {
  position: absolute;
  margin-left: 50px;
  width: 245px;
  z-index: -1;
}

.buttonStore {
  width: 120px;
  height: 40px;
  margin-left: 10px;
}

.arrowHero {
  position: absolute;
  left: 19%;
  width: 99px;
  top: 30%;
}

.arrowSection {
  position: absolute;
  right: 16%;
  top: 800px;
  width: 82px;
}

.vignetteBlancheHero {
  position: absolute;
  left: 5%;
  width: 13%;
  top: 7%;
  background-color: #fff;
  border-radius: 16px;
  padding: 10px;
  font-size: 16px;
  color: #101828;
  z-index: 99;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.titleClick {
  color: #6966ff;
}

.downArrow {
  max-width: 35px;
  position: relative;
  margin-top: -85px;
  margin-bottom: 30px;
}

#monthlyPricing .listItemWithBadge {
  display: flex; /* Utilise Flexbox */
  justify-content: space-between; /* Alignement horizontal */
  align-items: center; /* Alignement vertical */
  margin-bottom: 15px;
}

#monthlyPricing .listItemWithBadge .text {
  flex: 1; /* Permet au texte de prendre de l'espace mais laisse de la place pour la pastille */
  margin-right: 20px; /* Ajoute de l'espace entre le texte et la pastille */
}

#monthlyPricing .listItemWithBadge .soonPastilleHome {
  margin-left: auto; /* Pousse le badge vers la droite */
}

.videodashboardHome {
  width: 100%;
  border-radius: 10px;
  filter: drop-shadow(2px 17px 18px rgba(0, 0, 0, 0.07));
}

.modalPost {
  color: black;
  width: 400px;
  height: 200px;
  margin: auto;
  padding: 20px;
  border: 1px solid black;
  background: white;
  overflow: auto;
  text-align: center;
  position: relative;
  margin-top: 10%;
}

.mediaLogo {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.7;
  bottom: 40px;
  /* -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.02); */
  /* background-color: #24242b; */
  /* border-radius: 10px; */
}

.colorPrimary {
  color: #6866ff;
}

.accordionDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mediaLogo img {
  padding: 20px;
  display: inline-block;
  width: 130px;
  /* opacity: 0.6; */

  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  /* margin-bottom: 30px; */
}

.mediaLogo img:not(:last-of-type) {
  margin-right: 5%;
}

.gameLogoList {
  position: absolute;
  text-align: center;
  width: 75%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
}

.gameLogoList img {
  display: inline-block;
  width: 100px;
  mix-blend-mode: luminosity;
  margin-bottom: 30px;
  vertical-align: middle;
}
.gameLogoList img:not(:last-of-type) {
  margin-right: 10%;
}

.gameLogoList:last-of-type {
  z-index: 2;
}
.gameLogoList:last-of-type img {
  mix-blend-mode: normal;
  opacity: 0;
  transition: opacity 1s ease-out;
}
.gameLogoList:last-of-type img:hover {
  opacity: 1;
}

/* 
#BlogSection BACKGROUND GLITCH
 */
#BlogSection .mainBg {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}

/* #BlogSection .mainBg div {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(153deg, #FFD788 0%, #FFBFBF 65.52%, #C5C3FF 100%);
  background-size: cover;
  background-position: 50% 50%;
} */

#BlogSection .mainBgAda div {
  height: 70%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background-color: #fef3f2;
  background-image: url("/public/images/blog_section.jpg");
  background-position: center;
  background-size: cover; /* Couvre l'intégralité du conteneur */
  background-repeat: no-repeat; /* Empêche la répétition de l'image */
  border-bottom-left-radius: 200px; /* Arrondi le coin inférieur gauche */
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#BlogSection h1 {
  position: relative;
  color: #fff;
  text-align: center;
  font-family: "Gilroy", sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
}

#BlogSection h4 {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

#tabBetween {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  margin-top: -50px;
  animation: bounce 1s ease-in-out infinite;
  animation: bounce 1s ease-in-out infinite;
  -webkit-animation: bounce 1s ease-in-out infinite; /* Préfixe WebKit pour Chrome et Safari */
  -moz-animation: bounce 1s ease-in-out infinite; /* Préfixe Moz pour Firefox */
  -o-animation: bounce 1s ease-in-out infinite; /* Préfixe Opera */
}

.contentRegisterBefore {
  max-width: 60%;
  padding: 50px;
}

.containerTab {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 50px 75px 50px;
  border-radius: 20px;
  background-color: white;
  max-width: 800px;
  text-align: center;
  margin-bottom: 10px;
}

der-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#dashboardScreen {
  padding-bottom: 30px;
}

#dashboardScreen h3 {
  padding-bottom: 30px;
  padding-top: 20px;
}

#QuiEstDelhia2 {
  padding-top: 80px;
}

.founder-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-container {
  position: relative;
  flex: 1;
  max-width: 40%;
  margin-right: 0px;
}

.founder-image {
  width: 65%;
}

.vignetteBlancheSectionAda {
  position: absolute;
  top: 20px;
  left: 250px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 1rem;
  color: #000;
  text-align: center;
}

.text-containerQuiest {
  flex: 1.5;
  max-width: 60%; /* Ajustez la largeur du texte */
}

.text-containerQuiest2 {
  flex: 1.5;
  max-width: 50%; /* Ajustez la largeur du texte */
}

.text-containerQuiest h3 {
  font-size: 2rem;
  color: #101828;
  margin-bottom: 20px;
}

.paragraphQuiest {
  font-size: 1rem;
  color: #6b7280;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  .founder-section {
    flex-direction: column;
    text-align: center;
  }

  .image-container,
  .text-containerQuiest {
    max-width: 100%;
    margin: 0;
  }

  .vignetteBlancheSection {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .text-containerQuiest {
    margin-top: 20px;
  }
}

#feedbacksCustomers {
  padding-bottom: 30px;
  padding-top: 20px;
}

#feedbacksCustomers h3 {
  padding-bottom: 30px;
  padding-top: 20px;
}

#features {
  padding-bottom: 100px;
  padding-top: 20px;
}

#features img {
  width: 100%;
  margin-bottom: 10px;
  /* max-height: 150px;
  min-height: 150px; */
}

#features h3 {
  padding-bottom: 30px;
  padding-top: 20px;
}

.feature-item {
  position: relative;
  width: 100%;
}

.feature-item img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  display: block;
}

.feature-label {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
}

.feature-item img {
  border-radius: 15px; /* Arrondir les coins des images */
}

#commentCaMarche {
  position: relative; /* Assure que les éléments positionnés sont relatifs à ce conteneur */
  margin-top: 60px;
  padding: 50px 0;
  overflow: hidden; /* Pour éviter que l'image de fond dépasse les limites du conteneur */
}

.wrapL {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative; /* Permet au contenu de se superposer à l'image de fond */
  z-index: 1; /* Place le contenu au-dessus de l'image de fond */
}

.commentCaMarche-content {
  display: flex;
  position: relative; /* Permet au contenu d'être superposé sur l'image de fond */
  z-index: 2; /* Assure que ce contenu est au-dessus de l'image de fond */
}

.imageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Derrière le contenu */
  background-image: url("/public/assets/background_color.png");
  background-position: center;
  background-size: cover; /* Couvre l'intégralité du conteneur */
  background-repeat: no-repeat; /* Empêche la répétition de l'image */
}

.phone-container {
  width: 20%; /* L'image occupe 20% de la largeur disponible */
  display: flex;
  justify-content: center;
  position: relative;
}

.phoneSection {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(0 0 20px rgba(255, 99, 71, 0.5)); /* Effet de lueur */
}

.text-container {
  width: 75%; /* Le texte occupe 75% de la largeur disponible */
  padding-left: 50px;
  z-index: 2; /* Le texte est également au-dessus de l'arrière-plan */
}

.text-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-container ul li {
  font-family: "DM Sans";
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #101828;
  position: relative;
  padding: 10px 10px 10px 20px;
}

.text-container ul li:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: #d92d20;
}

.text-container ul li:not(:first-child):before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: #d92d20;
  /* background-color: #fecdca; */
}

@media screen and (max-width: 768px) {
  .commentCaMarche-content {
    flex-direction: column;
    text-align: center;
  }

  .text-container {
    padding-left: 0;
    margin-top: 30px;
    width: 100%;
  }

  .phone-container {
    width: 100%;
    margin-bottom: 20px;
  }

  #features {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .text-container ul li {
    padding: 15px;
  }

  .paragraphHome {
    width: 100%;
  }

  .text-container ul li:before {
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
  }
}

#highlightEventAda {
  position: relative; /* Assurez-vous que le conteneur parent est positionné relativement pour permettre à l'élément enfant de se positionner absolument */
  background-color: #fef3f2; /* Couleur de fond légèrement rose */
  padding: 50px 20px;
  border-radius: 15px; /* Bords arrondis */
  text-align: left;
  margin: 80px auto;
  margin-top: 150px;
  max-width: 77%;
}

.phoneHHighlight {
  position: absolute; /* Passer de relative à absolute pour un positionnement plus précis */
  width: 350px;
  right: 150px; /* Ajustez cette valeur pour déplacer l'image plus à gauche ou à droite */
  top: 50%; /* Ajustez cette valeur pour déplacer l'image verticalement */
  transform: translate(
    50%,
    -50%
  ); /* Ajustez la translation pour peaufiner l'emplacement */
}

.buttonStoreAda {
  margin-top: 10px;
  margin-left: -10px;
}

.highlight-contentAda {
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding-left: 50px;
}

.small-text {
  font-size: 1rem;
  color: #1d2939; /* Couleur grise pour le texte secondaire */
  margin-bottom: 10px;
}

.highlight-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #d92d20; /* Rouge vif pour attirer l'attention */
  margin-bottom: 20px;
}

.highlight-button {
  background-color: #101828; /* Couleur sombre pour le bouton */
  color: #ffffff;
  padding: 12px 24px;
  font-size: 1rem;
  border: none;
  border-radius: 50px; /* Bords arrondis pour un effet de bouton pill */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.highlight-button:hover {
  background-color: #1f2937; /* Couleur légèrement plus claire au survol */
}

.cta-button .tooltip {
  visibility: hidden;
  background-color: #d92d20;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  margin-top: -35px;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.cta-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.highlight-button .tooltip {
  visibility: hidden;
  background-color: #d92d20;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  margin-top: -35px;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.highlight-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

#histoireDeAnda {
  padding: 50px 0;
}

.wrapL {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.history-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-container {
  flex: 1;
  max-width: 40%;
  margin-right: 30px;
}

.rightFill {
  padding: 20px;
}

.leftFill {
  padding: 20px;
}

.logo-image {
  width: 100%;
  height: auto;
}

.text-container {
  flex: 2;
  max-width: 60%;
  color: #1d2939;
}

.text-container h2 {
  font-size: 2.5rem;
  color: #101828;
  margin-bottom: 20px;
}

.text-container h3 {
  font-size: 1.5rem;
  color: #d92d20;
  margin-bottom: 20px;
}

.text-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #1d2939;
}

.text-container .quote {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 20px;
  color: #101828;
}

@media screen and (max-width: 768px) {
  .history-section {
    flex-direction: column;
    text-align: center;
  }

  .image-container,
  .text-container {
    max-width: 100%;
    margin: 0;
  }

  .image-container {
    margin-bottom: 30px;
  }
}

#blogSection {
  padding: 60px 20px 0px;
  text-align: center;
}

.section-title {
  color: #1d2939;
  margin-bottom: 10px;
  text-align: center;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #1d2939;
  margin-bottom: 40px;
  text-align: center;
}

.blog-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}

.blog-card {
  /* background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  text-align: left;
  flex: 1;
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

.blog-card:hover {
  /* transform: translateY(-5px); */
  /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); */
}

.blog-image {
  width: 100%;
  height: 200px; /* Fixe une hauteur pour les images */
  object-fit: cover; /* Pour s'assurer que l'image ne se déforme pas */
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 15px;
}

.blog-title {
  font-size: 1.5rem;
  line-height: normal;
  font-weight: bold;
  color: #101828;
  margin-bottom: 10px;
}

.blog-excerpt {
  font-size: 1rem;
  color: #6b7280;
  margin-bottom: 20px;
}

.blog-link {
  font-size: 1rem;
  color: #d92d20;
  font-weight: bold;
  text-decoration: none;
}

.blog-link span {
  font-size: 1.2rem;
}

.view-all-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #101828;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.view-all-button:hover {
  background-color: #1f2937;
}

#pricing {
  padding-bottom: 20px;
  background: #aca9ff52;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 20px;
}

#pricing h4 {
  padding-bottom: 30px;
}

#pricing p {
  /* color: #596780; */
  margin-bottom: 10px;
}

#pricing ul {
  padding: 10px;
}

#pricing .green {
  color: #9cd323;
}

#pricing i {
  color: #596780;
  margin-right: 10px;
}

#pricing li {
  margin-bottom: 15px;
  color: #0d121f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
}

#pricing button {
  border-radius: 30px;
  background: #000;
  color: white;
  margin-top: 20px;
  padding: 15px;
  width: 100%;
}

#nosValeurs {
  padding: 60px 0;
  text-align: center;
}

.wrapL {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

#nosValeurs h2 {
  font-size: 2.5rem;
  color: #d92d20; /* Red color for the title */
  margin-bottom: 40px;
}

.values-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.value-item {
  flex: 1 1 30%;
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}

.value-item h3 {
  font-size: 1.5rem;
  color: #1d2939;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.value-item p {
  font-size: 1rem;
  color: #6b7280;
  line-height: 1.6;
}

.value-icon {
  width: 50px;
  height: 50px;
}

.cta-button {
  margin-top: 30px;
}

.cta-button button {
  background-color: #101828; /* Dark button color */
  color: #ffffff;
  padding: 12px 24px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button button:hover {
  background-color: #1f2937; /* Slightly lighter color on hover */
}

@media screen and (max-width: 768px) {
  .values-container {
    flex-direction: column;
    align-items: center;
  }

  .value-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

#nosMissions {
  padding: 60px 0;
  text-align: left;
}

.wrapL {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.mission-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-content {
  flex: 1;
  margin-right: 30px;
}

.text-content h2 {
  font-size: 2.5rem;
  color: #101828;
  margin-bottom: 30px;
  text-align: left;
}

.text-content p {
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 30px;
  text-align: left;
}

.mission-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.mission-list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #6b7280;
}

.mission-list li img {
  margin-right: 15px;
  width: 30px;
  height: 30px;
}

.image-content {
  flex: 1;
}

.mission-image {
  width: 75%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .mission-content {
    flex-direction: column;
  }

  .text-content {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

#monthlyPricing {
  padding-bottom: 20px;
  background: #ffdea952;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 20px;
}

.containerPricingUltimate h4 {
  color: #fff;
}

#monthlyPricing h4 {
  font-family: Mochiy Pop One;
  font-size: 23.333px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.583px; /* 83.929% */
  text-align: center;
  padding-bottom: 5px;
}

#monthlyPricing p {
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.5px; /* 137.5% */
}

#monthlyPricing ul {
  padding: 10px;
}

#monthlyPricing .green {
  color: #9cd323;
}

#monthlyPricing i {
  color: #596780;
  margin-right: 10px;
}

#monthlyPricing li {
  margin-bottom: 15px;
  color: #0d121f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
}

.containerPricingUltimate li {
  color: #fff !important;
}

.containerPricingUltimate button {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  /* vertical-align: middle; */
  background: #fff !important;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  width: 100%;
  color: #000 !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
}

#monthlyPricing button {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  /* vertical-align: middle; */
  background: #353535;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  width: 100%;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
}

.saveMoney {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #0d121f;
  border-radius: 30px;
  background: var(--primary-100, #e7defe);
  padding: 5px 10px;
  border: 2px solid #fff;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 60px;
}

.price {
  color: #0d121f;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

.align {
  vertical-align: middle;
  margin-right: 10px;
}

.surbare {
  color: var(--gray-300, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  text-decoration: line-through;
}

.containerPricing {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 30px 30px 30px;
  border-radius: 20px;
  background-color: #fff;
  /* max-width: 400px; */
  /* max-width: 30%; */
  display: inline-block;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
  /* min-height: 600px; */
}

.containerPricingUltimate {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 30px 30px 30px;
  border-radius: 20px;
  background: linear-gradient(
    177deg,
    rgba(175, 44, 255, 0.96) 2.72%,
    #d592ff 115.42%
  );
  /* max-width: 400px; */
  /* max-width: 30%; */
  display: inline-block;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
  /* min-height: 600px; */
}

.containerPricingUltimate .price {
  color: white;
}
.containerPricingUltimate span {
  color: white;
}

.containerPricingUltimate p {
  color: #fff;
}

.spannerLoader {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  z-index: 10;
  border-top: 3px solid #000;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rightArrow {
  position: absolute;
  right: 40%;
  /* right: 550px; */
}

.monthly {
  color: var(--secondary-700, #0d121f);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-right: 10px;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
}

.yearly {
  color: var(--secondary-700, #0d121f);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
}

.videohomePage {
  height: 50%;
  width: 100%;
  border-radius: 20px;
  border: 5px solid #000;
}

.helloVideo {
  position: absolute;
  z-index: 1;
  right: 19%;
  top: -177px;
  width: 152px;
}

.helloVignette {
  position: absolute;
  z-index: 1;
  right: 9%;
  top: -40px;
  width: 153px;
}

.containerVideo {
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  padding: 30px 30px 30px;
  border-radius: 20px;
  /* background-color: #f5f5f7; */
  max-width: 90%;
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
}

.videoWrapper {
  position: relative;
  /* autres styles */
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 36%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%; /* Pour rendre le fond rond */
  border: 5px solid white;
  cursor: pointer;
  background-image: url("../../public/playButton.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

/* Cacher le bouton de lecture lorsque la vidéo est en cours de lecture */
.videohomePage.playing + .playButton {
  display: none;
}

.parentContainer {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* This will create exactly three columns */
  gap: 30px; /* This is the space between the grid items */
}

.priceContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.containerFeaturesSoon {
  position: relative;
  border: 1px solid var(--gray-200, #d4d4d4);
  padding: 30px 30px 30px;
  border-radius: 20px;
  background: #fefefe;
  /* opacity:0.7; */
  display: inline-block;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
}

.containerFeatures {
  border: 1px solid var(--gray-200, #d4d4d4);
  padding: 30px 30px 30px;
  border-radius: 20px;
  background: #fefefe;
  display: inline-block;
  text-align: left;
  margin-left: 30px;
  margin-bottom: 10px;
}

.containerFeatures h4 {
  color: #272522;
  font-family: Mochiy Pop One;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.583px; /* 93.254% */
}

.containerFeatures p {
  margin-top: 20px;
  color: #7f7f7f;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.containerFeaturesSoon h4 {
  color: #272522;
  font-family: Mochiy Pop One;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.583px; /* 93.254% */
}

.containerFeaturesSoon p {
  margin-top: 20px;
  color: #7f7f7f;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

.containerTab p {
  margin-top: 20px;
}

/* @keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
} */

/*
    GLITCH ANIMATIONS
*/

@keyframes glitch-anim-flash {
  0%,
  5% {
    opacity: 0.2;
    transform: translate3d(10px, 100vh, 0);
  }
  5.5%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes glitch-anim {
  0% {
    clip: rect(100px, 9999px, 11px, 0);
  }
  5% {
    clip: rect(79px, 9999px, 36px, 0);
  }
  10% {
    clip: rect(119px, 9999px, 2px, 0);
  }
  15% {
    clip: rect(134px, 9999px, 40px, 0);
  }
  20% {
    clip: rect(62px, 9999px, 12px, 0);
  }
  25% {
    clip: rect(137px, 9999px, 84px, 0);
  }
  30% {
    clip: rect(88px, 9999px, 105px, 0);
  }
  35% {
    clip: rect(146px, 9999px, 30px, 0);
  }
  40% {
    clip: rect(27px, 9999px, 39px, 0);
  }
  45% {
    clip: rect(122px, 9999px, 7px, 0);
  }
  50% {
    clip: rect(42px, 9999px, 33px, 0);
  }
  55% {
    clip: rect(88px, 9999px, 20px, 0);
  }
  60% {
    clip: rect(128px, 9999px, 100px, 0);
  }
  65% {
    clip: rect(114px, 9999px, 56px, 0);
  }
  70% {
    clip: rect(40px, 9999px, 24px, 0);
  }
  75% {
    clip: rect(64px, 9999px, 40px, 0);
  }
  80% {
    clip: rect(119px, 9999px, 27px, 0);
  }
  85% {
    clip: rect(148px, 9999px, 42px, 0);
  }
  90% {
    clip: rect(42px, 9999px, 140px, 0);
  }
  95% {
    clip: rect(87px, 9999px, 127px, 0);
  }
  100% {
    clip: rect(41px, 9999px, 39px, 0);
  }
}
@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(10px, 0, 0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(10px, 0, 0);
  }
  22%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}
@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * 10px), 0, 0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * 10px), 0, 0);
  }
  22%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}
@keyframes glitch-anim-4 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
  }
  22%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

/*
  END GLITCH ANIMATIONS
*/

/* HOW IT WORKS */

#HowItWorks {
  margin-top: 100px;
  margin-bottom: 60px;
}

/* HOW IT WORKS */

#HowItWorks ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#HowItWorks img {
  width: 20%;
  margin-left: 20%;
  margin-bottom: 20px;
}

#HowItWorks h2 {
  display: block !important;
  margin-bottom: 0px !important;
}

#HowItWorks ul li .content {
  width: 40%;
  position: relative;
}
#HowItWorks ul li .content p,
#HowItWorks ul li .content h3 {
  max-width: 300px;
}

#HowItWorks ul li img {
  width: 60%;
  max-width: 1000px;
}

#HowItWorks ul li:nth-of-type(2) .content {
  order: 1;
}
#HowItWorks ul li:nth-of-type(2) img {
  order: 2;
}

#HowItWorks ul li:not(:last-of-type) {
  margin-bottom: 50px;
}
#HowItWorks ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}

#HowItWorks ul li:first-of-type .content:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url("/./public/gamesgrid.png") repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}

.vignetteCardBlur {
  width: 310px;
}

#infoWhatIsIt .tac {
  overflow-x: hidden; /* Cela cache les parties des images qui sortent de l'écran */
}

.containerVignette {
  display: flex; /* Affiche vos images en ligne */
  animation: scroll-left 20s linear infinite; /* Ajustez la durée selon vos besoins */
  width: 20%; /* Assure que le conteneur prend la largeur totale de tous les enfants */
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -100%
    ); /* Déplace le contenu de 100% de sa largeur vers la gauche */
  }
}

.arrowhomeFree {
  display: flex; /* Utilisez flexbox pour aligner les éléments horizontalement */
  align-items: center; /* Centre les éléments verticalement dans le conteneur */
  justify-content: left;
  margin-top: 20px;
  margin-left: -150px;
}

.freeArrow {
  max-width: 80px;
  margin-left: 20px;
}

.freedisplay {
  color: var(--gray-500, var(--grey-500-black, #000));
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  font-family: Mochiy Pop One;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.115px;
  min-width: 55%;
}

.no_credit_card_required {
  color: #000;
  font-family: Mochiy Pop One;
  font-size: 11px;
  min-width: 50%;
}

.vignetteCard {
  min-width: 20%; /* Utilisez min-width au lieu de width pour éviter le redimensionnement des images */
  flex-shrink: 0; /* Empêche les images de se redimensionner lors du défilement */
}

.containerVignetteBlur {
  /* filter: blur(8px); */
  z-index: -1;
  /* opacity: 0.9; */
  top: -120px;
  position: absolute;
}

#infoWhatIsIt {
  margin-top: -230px;
  /* margin-bottom: 130px; */
  position: relative;
  z-index: 0;
}

@media screen and (min-width: 1920px) {
  #infoWhatIsIt {
    margin-top: -310px;
  }
}

/* infoWhatIsIt */

#infoWhatIsIt ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#infoWhatIsIt ul li .content {
  width: 40%;
  position: relative;
}
#infoWhatIsIt ul li .content p,
#infoWhatIsIt ul li .content h3 {
  max-width: 300px;
}

#infoWhatIsIt ul li img {
  width: 60%;
  max-width: 1000px;
}

#infoWhatIsIt ul li:nth-of-type(2) .content {
  order: 1;
}
#infoWhatIsIt ul li:nth-of-type(2) img {
  order: 2;
}

#infoWhatIsIt ul li:not(:last-of-type) {
  margin-bottom: 50px;
}
#infoWhatIsIt ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}

#infoWhatIsIt ul li:first-of-type .content:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url("/./public/gamesgrid.png") repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}

#Price {
  margin-top: 100px;
  margin-bottom: 60px;
}

#Price .cta {
  margin-top: 30px;
  margin-bottom: 30px;
}
#Price .ctaWhite {
  margin-top: 30px;
  margin-bottom: 30px;
}

#Price img {
  width: 20%;
}

#Price h2 {
  display: block !important;
  margin-bottom: 0px !important;
}

#Price ul li:nth-of-type(2) .content {
  order: 1;
}
#Price ul li:nth-of-type(2) img {
  order: 2;
}

.vignetNumber {
  background-color: #393953;
  border-radius: 10px;
  max-width: 25%;
  margin: 30px;
  display: inline-block;
  transform: rotate(-1deg);
}

.vignetNumber h1 {
  background-color: #393953;
  border-radius: 10px;
  font-weight: bold;
  font-size: 30px;
}

.vignetNumber h4 {
  background-color: #393953;
  border-radius: 10px;
  max-width: 80%;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.5rem;
  font-weight: 400;
}

.vignetNumber2 {
  background-color: #393953;
  border-radius: 10px;
  max-width: 28%;
  margin: 30px;
  display: inline-block;
  transform: rotate(3deg);
}

.vignetNumber2 h1 {
  background-color: #393953;
  border-radius: 10px;
  font-weight: bold;
  font-size: 30px;
}

.vignetNumber2 h4 {
  background-color: #393953;
  border-radius: 10px;
  max-width: 80%;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.5rem;
  font-weight: 400;
}

.curvedArrow {
  position: absolute;
  left: 65%;
  width: 33% !important;
}

.curvedArrowWay {
  position: absolute;
  left: 65%;
  bottom: 0px;
  width: 33% !important;
}

.mr5 {
  margin-right: 5px;
}

.yellow {
  color: #f79330;
}

.circle {
  position: relative;
  top: -50px;
  margin-left: 25% !important;
}

.sparkle {
  top: -70px;
  left: 16%;
  width: 5% !important;
  position: relative;
}

.itemPrice {
  background-color: #fff;
  text-align: left;
  display: inline-block;
  color: #5d5a88;
  width: 40%;
  margin: 10px;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
}

.itemPrice h3 {
  font-size: 45px;
  display: inline-block;
}

.itemPriceFeatured {
  background-color: #393953;
  text-align: left;
  display: inline-block;
  color: #fff;
  width: 40%;
  top: 20px;
  margin: 10px;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
}

.itemPrice hr {
  border: 1px solid #d4d2e3;
  margin-bottom: 40px;
}

.itemPriceFeatured hr {
  border: 1px solid #d4d2e3;
  margin-bottom: 40px;
}

.itemPriceFeatured h3 {
  font-size: 45px;
  display: inline-block;
}

.itemPriceCredits {
  background-color: #fff;
  text-align: left;
  display: inline-block;
  color: #5d5a88;
  width: 90%;
  margin: 10px;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
}

.itemPriceCredits h3 {
  font-size: 45px;
  display: inline-block;
}

.text {
  color: #5d5a88;
}

.itemPrice h6 {
  color: #5d5a88;
}

.itemPrice h3 {
  color: #5d5a88;
}

.itemPrice ul {
  /* line-height:0rem; */
}

.itemPrice li {
  text-align: left;
  /* margin-right:10px; */
}

.p30 {
  padding: 30px;
}

#NowGo {
  margin-top: 150px;
  margin-bottom: 60px;
}

#NowGo .tac {
  background-image: linear-gradient(to bottom right, #6966ff, #bcbbf886);
  border-radius: 10px;
  padding: 30px;
}

#NowGo .tac .ctaWhite {
  margin-top: 40px;
}

#NowGo .tac p {
  margin-bottom: 0px;
}

#NowGo ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#NowGo img {
  width: 20%;
}

#NowGo h2 {
  display: block !important;
  margin-bottom: 0px !important;
}

#NowGo ul li .content {
  width: 40%;
  position: relative;
}
#NowGo ul li .content p,
#NowGo ul li .content h3 {
  max-width: 300px;
}

#NowGo ul li img {
  width: 60%;
  max-width: 1000px;
}

#NowGo ul li:nth-of-type(2) .content {
  order: 1;
}
#NowGo ul li:nth-of-type(2) img {
  order: 2;
}

#NowGo ul li:not(:last-of-type) {
  margin-bottom: 50px;
}
#NowGo ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}

#NowGo ul li:first-of-type .content:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url("/./public/gamesgrid.png") repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}

#freeNow {
  max-width: 70%;
  border-radius: 30px;
  background: linear-gradient(135deg, #ffd68a 0%, #ffc0be 46.35%, #e0c1e1 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 150px;
  margin-bottom: 60px;
  overflow: hidden;
}

#freeNow h4 {
  color: #272522;
  text-align: left;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
}

#freeNow p {
  margin-top: 40px;
}

#freeNow img {
  max-width: 50%;
  position: relative;
  bottom: 0px;
  top: 40px;
  left: 20px;
}

#freeNow button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  margin-top: 10px;
  text-transform: uppercase;
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  transition: all 0.1s ease-out;
  transform: scale(1);
}

#FAQ {
  margin-top: 150px;
  margin-bottom: 60px;
}

#FAQ ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#FAQ h2 {
  display: block !important;
  margin-bottom: 0px !important;
}

#FAQ ul li .content {
  width: 40%;
  position: relative;
}
#FAQ ul li .content p,
#FAQ ul li .content h3 {
  max-width: 300px;
}

#FAQ ul li img {
  width: 60%;
  max-width: 1000px;
}

#FAQ ul li:nth-of-type(2) .content {
  order: 1;
}
#FAQ ul li:nth-of-type(2) img {
  order: 2;
}

#FAQ ul li:not(:last-of-type) {
  margin-bottom: 50px;
}
#FAQ ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}

#FAQ ul li:first-of-type .content:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url("/./public/gamesgrid.png") repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}

/* BENEFITS */

#WhitelistBenefits {
  margin-bottom: 100px;
}

#WhitelistBenefits .listWrap {
  background: rgba(0, 0, 0, 0.1);
  padding: 80px 20px;
}

#WhitelistBenefits ul {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
#WhitelistBenefits ul:last-of-type {
  margin-left: 80px;
}
#WhitelistBenefits ul li {
  max-width: 600px;
  margin-bottom: 10px;
  padding: 20px 0;
  display: inline-block;
}

#WhitelistBenefits ul li i {
  margin-right: 10px;
  color: #dd1754;
}

#WhitelistBenefits ul li p {
  font-size: 0.833rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Vos styles pour les écrans de 13 pouces ici */
/* @media screen and (min-width: 900px) and (max-width: 1366px) {
  .referalBack {
    display:none;
  }

  .newPastille {
    font-size:8px;
  }

  .containerFeatures {
    max-width:250px;
    min-height:430px;
    max-height:430px;
  }
  .containerFeatures h4 {
    font-size:17px;
  }
  .containerFeaturesSoon {
    max-width:250px;
    min-height:430px;
    max-height:430px;
  }
  .containerFeaturesSoon h4 {
    font-size:17px;
  }
} */

/* RESPONSIVE Telephone */

@media screen and (max-width: 1024px) {
  #BlogSection {
    height: 90vh;
    margin-bottom: -150px;
  }
  #BlogSection .mainBg div {
    background-position-x: 75%;
    background-position-y: 50%;
  }
  #BlogSection .wrap {
    top: 0;
    transform: none;
    padding-top: 100px;
  }
  .gameLogoList {
    display: none;
  }

  #allArticles {
  }

  .vectorHeroADA {
    position: absolute;
    right: 32%;
    width: 35%;
    z-index: -1;
  }

  .containerVignetteBlur {
    display: none;
  }

  #phoneOFF {
    display: none;
  }

  #Screen {
    display: none;
  }

  #Banner {
    display: none;
  }

  .founder-image {
    width: 65%;
    margin-top: 30px;
  }

  .mediaLogo {
    position: inherit;
    text-align: center;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 40px;
  }

  .modalPost {
    color: black;
    width: 70%;
    height: 30%;
    margin: auto;
    padding: 20px;
    border: 1px solid black;
    background: white;
    overflow: auto;
    text-align: center;
    position: relative;
    margin-top: 50%;
  }

  .text-containerQuiest {
    flex: 1.5;
    max-width: 100%; /* Ajustez la largeur du texte */
  }

  .text-containerQuiest2 {
    flex: 1.5;
    max-width: 100%; /* Ajustez la largeur du texte */
  }

  .vignetNumber {
    background-color: #393953;
    border-radius: 10px;
    max-width: 100% !important;
    margin: 30px;
    display: inline-block;
    transform: rotate(0deg);
  }
  .vignetNumber2 {
    background-color: #393953;
    border-radius: 10px;
    max-width: 100% !important;
    margin: 30px;
    display: inline-block;
    transform: rotate(0deg);
  }

  .arrowhomeFree {
    display: none;
  }

  .containerVignette {
    display: flex; /* Affiche vos images en ligne */
    animation: scroll-left 20s linear infinite; /* Ajustez la durée selon vos besoins */
    width: 80%; /* Assure que le conteneur prend la largeur totale de tous les enfants */
  }

  .highlight-contentAda {
    display: flex;
    flex-direction: column;
    align-items: normal;
    padding-left: 0px;
  }

  #HowItWorks h2 {
    text-align: center;
    padding-left: 0px !important;
  }

  #HowItWorks {
    margin-top: 100px;
  }

  .avisDisplay {
    display: none;
  }

  .vectorHero {
    position: absolute;
    margin-left: -13%;
    width: 45%;
  }

  .arrondiIn {
    display: none;
  }

  .phoneHHighlight {
    display: none;
  }

  #BlogSection h1 {
    position: relative;
    color: #ffffff;
    /* min-height: 350px;
    max-height: 350px; */
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    font-size: 40px;
    line-height: 60px;
    /* min-height: 380px;
    max-height: 380px; */
  }

  #highlightEventAda {
    position: relative; /* Assurez-vous que le conteneur parent est positionné relativement pour permettre à l'élément enfant de se positionner absolument */
    background-color: #fef3f2; /* Couleur de fond légèrement rose */
    padding: 50px 20px;
    border-radius: 15px; /* Bords arrondis */
    text-align: center;
    margin: 80px auto;
    margin-top: 150px;
    max-width: 90%;
  }

  .inputSearch {
    background-color: white;
    font-family: "Plus Jakarta Sans";
    text-align: left;
    border-radius: 77px;
    padding-left: 3%;
    font-size: 15px;
    color: #52525b;
    height: 56px;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 500;
    position: relative;
    z-index: 2;
    font-size: 12px;
  }

  #freeNow {
    max-width: 70%;
    border-radius: 30px;
    background: linear-gradient(
      135deg,
      #ffd68a 0%,
      #ffc0be 46.35%,
      #e0c1e1 100%
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    overflow: hidden;
  }

  #NowGo h2 {
    text-align: center;
    padding-left: 0px !important;
    margin-top: 15px;
  }

  .contentRegisterBefore {
    max-width: 100%;
  }

  #infoWhatIsIt h2 {
    text-align: center;
    padding-left: 0px !important;
  }

  .SocialsVign {
    width: 100%;
    margin-top: 10px;
  }

  .joinBuskaForFree {
    margin-top: 20px;
  }

  #infoWhatIsIt {
    margin-top: -80px;
    margin-bottom: 0px;
  }

  .descriptifPrice {
    white-space: normal;
  }

  .webkitBox {
    display: block;
  }

  .lineHeight {
    display: inline-block;
    vertical-align: super;
  }

  .SocialsVignSecond {
    width: 290px;
  }

  .containerFeaturesSoon {
    max-width: 400px;
    min-height: none;
    max-height: none;
    margin-left: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .blog-title {
    font-size: 1.8rem;
    line-height: normal;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .containerFeatures {
    max-width: 400px;
    min-height: none;
    max-height: none;
    margin-left: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .instagramBack {
    display: none;
  }

  .youtubeBack {
    display: none;
  }

  .referalBack {
    display: none;
  }
  .linkedinBack {
    display: none;
  }
  .pinterestBack {
    display: none;
  }
  .redditBack {
    display: none;
  }
  .referal1Back {
    display: none;
  }
  .slackBack {
    display: none;
  }

  .slackStyleBack {
    top: 0px;
    right: 20%;
  }

  .accordion {
    max-width: 95%;
  }

  .itemPrice {
    width: 100%;
  }

  .itemPriceFeatured {
    width: 100%;
  }

  #Price h2 {
    text-align: center;
    padding-left: 0px !important;
  }

  #FAQ {
    margin-top: 50px;
    margin-bottom: auto;
  }

  .dashboardHome {
    display: none;
  }

  .containerVideo {
    margin-left: 0px;
    max-width: 100%;
    padding: 10px 20px 20px;
    margin-bottom: 0px;
  }

  .saveMoney {
    display: block;
    width: fit-content;
    margin-top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .containerPricing {
    /* margin-left: 0px !important; */
    max-width: 400px;
  }

  .secondRow {
    display: none;
  }

  .helloVignette {
    display: none;
  }

  .containerPricingUltimate {
    /* margin-left: 0px !important; */
    max-width: 400px;
  }

  #globalFooter h3 {
    margin-right: 0px;
  }

  .parentContainer {
    grid-template-columns: 1fr; /* One column for mobiles */
    justify-content: center;
  }

  .priceContainer {
    grid-template-columns: 1fr; /* One column for mobiles */
    justify-content: center;
  }

  .containerFeatures,
  .containerFeaturesSoon {
    /* Votre CSS existant reste le même, supprimez simplement le margin-left */
    margin: 0 auto; /* Ajouté pour remplacer margin-left: 30px; */
  }

  .containerPricing,
  .containerPricingUltimate {
    /* Votre CSS existant reste le même, supprimez simplement le margin-left */
    margin: 0 auto; /* Ajouté pour remplacer margin-left: 30px; */
  }

  .subFooter {
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  #FAQ h2 {
    text-align: center;
    padding-left: 0px !important;
  }

  /* #FAQ img {
    width: 100%;
    height: 100%;
    top: -70px;
  } */

  .sparkle {
    top: -50px;
    left: 50%;
    width: 5% !important;
    position: relative;
  }

  #HowItWorks ul li {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
  }
  #HowItWorks ul li:first-of-type .content:before {
    background-size: 90%;
  }
  #HowItWorks ul li img {
    width: 100%;
  }
  #HowItWorks ul li .content {
    width: 100%;
  }
  #HowItWorks ul li .content p,
  #HowItWorks ul li .content h3 {
    max-width: inherit;
  }
  #HowItWorks ul li:nth-of-type(2) img {
    order: 1;
  }
  #HowItWorks ul li:nth-of-type(2) .content {
    order: 2;
  }

  #WhitelistBenefits {
    margin-bottom: 60px;
  }
  #WhitelistBenefits .listWrap {
    padding: 60px 40px;
  }
  #WhitelistBenefits .wrap {
    max-width: 100%;
  }
  #WhitelistBenefits ul {
    display: block;
  }
  #WhitelistBenefits ul:last-of-type {
    margin-left: 0;
  }

  #HowItWorks .wrap {
    max-width: 90%;
  }

  .helloVideo {
    display: none;
    /* position: absolute;
    z-index: 1;
    right: 15%;
    top: -160px;
    width: 45%; */
  }

  .alignVignHome {
    max-width: 100%;
  }

  #dashboardScreen {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  #feedbacksCustomers {
    padding-top: 0px;
  }
}

/* Pour les écrans avec une résolution standard */
@media screen and (min-width: 900px) and (max-width: 1366px) {
  .backWrapper {
    top: 30px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1280px) and (min-height: 800px) and (max-height: 800px) {
  .backWrapper {
    top: 30px;
  }
}
