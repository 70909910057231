.feedLoader {
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 400px;
  margin: 20px auto;
}

.feedLoader h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.feedLoader p {
  font-size: 16px;
  color: #666;
}
