.creditPacksContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .creditPack {
    background-color: #0e0e1a;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    width: 30%;
    text-align: center;
  }

  .creditPack p {
    padding: 15px;
  }
  
  .month {
    font-size:13px;
  }