.related-articles {
  margin-top: 50px;
  padding: 20px;
  /* background-color: #fef3f2; */
  padding-bottom: 50px;
}

.related-articles h2 {
  text-align: center;
  font-size: 24px;
  color: #e63946;
  margin-bottom: 30px;
}

.articles-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap; /* Permet aux éléments de se réorganiser sur plusieurs lignes */
}

.article-item {
  padding: 20px;
  width: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-item h3 {
  font-family: "DM Sans";
  font-size: 24px;
  color: #101828;
}

.article-item p {
  font-family: "DM Sans";
  font-size: 16px;
  text-align: left;
  color: #101828;
  min-height: 60px; /* Ajustez cette hauteur minimale selon vos besoins */
  margin-bottom: 15px; /* Assure de l'espace entre le paragraphe et le lien */
  flex-grow: 1; /* Permet au paragraphe de prendre tout l'espace disponible */
}

.article-image {
  width: 100%;
  height: 200px; /* Fixe une hauteur pour les images */
  object-fit: cover; /* Pour s'assurer que l'image ne se déforme pas */
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 15px;
}

.read-more {
  color: #e63946;
  font-weight: bold;
  text-decoration: none;
  margin-top: auto; /* Assure que le lien "Voir l'article" est aligné en bas */
  display: inline-block;
}

/* Styles pour les petits écrans */
@media screen and (max-width: 768px) {
  .articles-grid {
    flex-direction: column; /* Empile les articles en colonne */
    gap: 30px;
  }

  .article-item {
    width: 100%; /* Chaque article prend toute la largeur */
    padding: 10px; /* Réduit le padding */
  }

  .related-articles h2 {
    font-size: 20px; /* Réduit légèrement la taille de la police pour les titres */
    margin-bottom: 20px;
  }

  .article-item h3 {
    font-size: 20px; /* Réduit la taille des titres des articles */
  }

  .article-item p {
    font-size: 14px; /* Réduit la taille du texte de description */
    text-align: center; /* Centre le texte pour une meilleure lisibilité en mobile */
  }

  .read-more {
    font-size: 14px; /* Ajuste la taille du bouton "Voir l'article" */
    margin-top: 5px;
  }
}
