.custom-modal {
  border-radius: 10px;
  padding: 20px;
}

.modal-content {
  padding: 20px;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.modal-description {
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 30px;
}

#buttonWaitlist {
  text-align: right;
  margin-top: 30px;
}

.custom-input {
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
}

.custom-radio-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border-radius: 5px;
}

.modal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 570px; /* Ajuste cette hauteur en fonction de la taille souhaitée */
  overflow: hidden; /* Pour couper l'image si elle dépasse */
}

.modal-image {
  width: 100%; /* Pour s'assurer que l'image prend toute la largeur */
  height: 100%; /* Pour s'assurer que l'image remplit la hauteur du container */
  object-fit: cover; /* Rognage de l'image si nécessaire pour remplir l'espace */
  border-radius: 10px;
}

.role-selection-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.role-button {
  width: 48%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.role-button:hover {
  background-color: #f5f5f5;
}

.role-button.ant-btn-default:not(:disabled):hover {
  color: #3b3b3b !important;
  box-shadow: none !important;
  border: none !important;
}

.role-button.selected {
  background-color: #ffeded;
  border-color: #ffeded;
  color: #ff4d4f;
}

.custom-input {
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
}

@media screen and (max-width: 1024px) {
  #buttonWaitlist {
    text-align: center;
    margin-top: 30px;
  }

  .role-button {
    width: 48%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
}
