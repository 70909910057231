.plans-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(31px);
}

.plans-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto 0;
}

.monthlyPrice {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
}

.activehere {
  color: #000;
  border-radius: 37px;
  background: #fff;
  padding: 0px 10px;
}

.yearlyPrice {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  margin-left: 10px;
}

.switchText {
  color: var(--gray-500, #000);
  text-align: center;
  font-family: Mochiy Pop One;
  font-size: 16.752px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.06px; /* 83.929% */
}

.modalPlanList h6 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.825px;
}

.arrowImage {
  rotate: 0deg;
}

.monthlyPrice.activehere + .yearlyPrice {
  color: #fff;
}

.pricingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Espace entre les éléments */
  position: relative;
  left: 130px;
}

.monthlyorYearly {
  background-color: #000;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
}

.plans-modal .ant-modal-content {
  background-color: transparent !important;
  border-radius: 20px;
  height: 100%;
  width: 100%;
}

.plans-modal .ant-modal-close-x {
  background-color: #f2f2f2;
  border-radius: 71px;
}

.plans-header {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.plan-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.logoPricing {
  margin-bottom: 25px;
  width: 13%;
}

.containerPricingPlans {
  position: relative;
}

.containerPricingPlansUltimate {
  position: relative;
}

.relaxImageWrapper {
  position: absolute;
  right: 30px;
  bottom: 12%;
  z-index: 1;
}

.activeLink {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.buyButton {
  position: relative; /* Pour que le bouton soit au-dessus de l'image si les deux se chevauchent */
  z-index: 2;
}

.pricingSwitchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrowAndText {
  display: flex;
  align-items: center;
}

.arrowAndText img {
  margin-right: 10px; /* Ajustez la marge comme nécessaire */
}

.containerPricingPlans {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 30px 30px 30px;
  border-radius: 20px;
  background-color: #f5f5f7;
  /* max-width: 400px; */
  max-width: 25%;
  display: inline-block;
  margin-left: 30px;
  margin-bottom: 10px;
}

.containerPricingPlans h4 {
  color: var(--Grey-500, #000);
  font-family: Mochiy Pop One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-align: center;
}

.containerPricingPlansUltimate {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 30px 30px 30px;
  border-radius: 20px;
  background: linear-gradient(
    177deg,
    rgba(175, 44, 255, 0.96) 2.72%,
    #d592ff 115.42%
  );
  /* max-width: 400px; */
  max-width: 25%;
  display: inline-block;
  margin-left: 30px;
  margin-bottom: 10px;
  color: #fff;
}

.containerPricingPlans li {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  letter-spacing: -0.36px;
  padding: 5px 0px;
}

.containerPricingPlans li span {
  margin-left: 10px;
}

.containerPricingPlansUltimate li {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  letter-spacing: -0.36px;
  padding: 5px 0px;
}

.containerPricingPlansUltimate li span {
  margin-left: 10px;
  color: #fff;
}

.containerPricingPlans .buyButton {
  padding: 14px;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  margin-top: 20px;
  background: #000;
}

.containerPricingPlans .buyButton span {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

.containerPricingPlansUltimate .buyButton {
  padding: 14px;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  margin-top: 20px;
  background: var(--Grey-100, #f2f2f2);
}

.containerPricingPlansUltimate .buyButton span {
  color: var(--Grey-500, #000);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

.greenPlan {
  color: #90c814;
}

.grey {
  color: #666;
}

.containerPricingPlansUltimate h4 {
  color: #fff;
  font-family: Mochiy Pop One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-align: center;
}

.containerPricingPlansUltimate .descriptifPriceTitle {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28.8px; /* 160% */
}

.containerPricingPlans .descriptifPriceTitle {
  color: var(--Grey-300, #666);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28.8px; /* 160% */
}

.ligne {
  margin-top: 10px;
  background: #f2f2f2;
  height: 1px;
  margin-bottom: 10px;
}

.pro-plan {
  background-color: #ffffff;
}

.ultimate-plan {
  background-color: #6d6de0;
}

.plan h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.plan p {
  font-size: 18px;
  margin-bottom: 20px;
}

.price {
  color: #0d121f;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 160% */
}

.monthsPrices {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px; /* 160% */
  vertical-align: super;
}

.containerPricingPlansUltimate .monthsPrices {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px; /* 160% */
  vertical-align: super;
}

.containerPricingPlans p {
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
  line-height: 20.8px; /* 138.667% */
}

.containerPricingPlansUltimate p {
  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
  line-height: 20.8px; /* 138.667% */
}

.containerPricingPlansUltimate .price {
  color: #fff;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 160% */
}
