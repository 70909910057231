.blog-post-container {
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.blog-header {
  position: relative;
  color: white;
  text-align: center;
}

.blog-header-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

.blog-header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: white;
  padding: 20px;
}

.blog-title {
  font-family: "Gilroy";
  color: white;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  margin-bottom: 10px;
}

.blog-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: "DM Sans";
}

.blog-date {
  font-family: "DM Sans";
  font-size: 18px;
  opacity: 0.8;
  color: #fda29b;
  line-height: 27px;
}

.blog-content {
  padding: 30px 20px;
  max-width: 960px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.6;
}

.blog-content img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.blog-content h1 {
  font-family: "DM Sans";
  font-size: 32px;
  /* margin-top: 40px; */
}

.blog-content h2 {
  font-family: "DM Sans";
  font-size: 24px;
  margin-top: 40px;
}

.blog-content p {
  font-family: "DM Sans";
  font-size: 18px;
  margin-bottom: 20px;
}

@media (max-width: 480px) {
  .blog-header-image {
    max-height: 502px;
    height: 400px;
  }

  .blog-header-content {
    position: absolute;
    top: 55%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: white;
    padding: 20px;
    width: 90%;
  }

  .blog-title {
    font-size: 24px; /* Réduit la taille du titre pour les très petits écrans */
    line-height: 30px;
  }

  .blog-subtitle {
    font-size: 14px; /* Réduit encore la taille du sous-titre */
  }

  .blog-content {
    padding: 15px 5px; /* Réduit davantage le padding */
    font-size: 14px; /* Réduit encore la taille de la police pour le texte */
  }

  .blog-content h1 {
    font-size: 24px; /* Ajuste la taille des titres principaux */
  }

  .blog-content h2 {
    font-size: 20px; /* Ajuste la taille des sous-titres */
  }

  .blog-content p {
    font-size: 14px; /* Ajuste la taille du texte pour les paragraphes */
  }
}
