/* GOOGLE CONNECT BUTTON */

.googleConnectButton {
  display: inline-block;
  /* margin-left: 30px; */
}
.googleConnectButton button {
  border-radius: 50px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  vertical-align: middle;
}
.googleConnectButton button > div {
  margin-right: 0 !important;
  background: none !important;
  border-radius: 0 !important;
  margin: 0 4px 0 10px !important;
  height: 18px !important;
  width: 18px !important;
  padding: 0 !important;
}
.googleConnectButton button > span {
  color: #000;
}

.ctaGoogle {
  padding: 0px !important;
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .googleConnectButton {
    margin-left: 0px;
  }
}
