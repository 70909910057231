.containerPlan {
  max-width: 50%;
  display: inline-block;
  padding-bottom: 10px;
}

.paddingContain {
  padding: 20px;
}

.containerPlan h4 {
  color: var(--Grey-500, #000);
  font-family: Mochiy Pop One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-align: center;
}

.containerPlan li {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  letter-spacing: -0.36px;
  padding: 5px 0px;
}

.containerPlan li span {
  margin-left: 10px;
}

.containerPlan .buyButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  vertical-align: middle;
  background: #353535;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  /* margin-bottom: 10px; */
  margin-top: 10px;
  transform: scale(1);
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  width: 95%;
}

.containerPlan .buyButton span {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

.containerPlansUltimate {
  background: linear-gradient(
    177deg,
    rgba(175, 44, 255, 0.96) 2.72%,
    #d592ff 115.42%
  );
  max-width: 50%;
  display: inline-block;
  padding-bottom: 10px;
  color: #fff;
}

.containerPlansUltimate li {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  letter-spacing: -0.36px;
  padding: 5px 0px;
}

.containerPlansUltimate li span {
  margin-left: 10px;
  color: #fff;
}

.containerPlansUltimate .buyButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  vertical-align: middle;
  background: #f2f2f2;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  transform: scale(1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  width: 95%;
}

.containerPlansUltimate .buyButton span {
  color: var(--Grey-500, #000);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px; /* 160% */
}

.containerPlansUltimate h4 {
  color: #fff;
  font-family: Mochiy Pop One;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-align: center;
}

.containerPlansUltimate .descriptifPriceTitle {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28.8px; /* 160% */
}

.containerPlan .descriptifPriceTitle {
  color: var(--Grey-300, #666);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: 28.8px; /* 160% */
}

.containerPlansUltimate .monthsPrices {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px; /* 160% */
  vertical-align: super;
}

.containerPlan p {
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
  line-height: 20.8px; /* 138.667% */
}

.containerPlansUltimate p {
  color: #fff;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 10px;
  line-height: 20.8px; /* 138.667% */
}

.containerPlansUltimate .price {
  color: #fff;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 160% */
}
