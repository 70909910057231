.userDashboard {
  /* padding-top: 80px; */
  /* background-color: #FFF; */
}

.userDashboard .wrap {
  min-height: calc(100vh - 220px - 80px - 150px);
}

.userDashboard main {
  overflow-x: hidden;
  overflow-y: auto;
  display: inline-block;
  vertical-align: top;
}

.userDashboard main {
  /* min-width: 83%; */
  /* width: calc(90% - 220px); */
  /* margin-left: 17%; */
  /* position: relative; */
  /* background: #FFF; */
  width: 100%;
  border-radius: 10px;
  padding: 5%;
  color: black;
  margin-top: 20px;
}
.userDashboard main h1 {
  margin-bottom: 30px;
  font-size: 3rem;
}

.userDashboard main fieldset > div {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  max-width: 400px;
  min-width: 250px;
  margin-bottom: 10px;
}
.userDashboard main fieldset > div {
  margin-left: 2%;
}

.userDashboard main .labelUppercase {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ctaLink.powerOff {
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.4;
}
.ctaLink.powerOff:hover {
  text-decoration: none;
  opacity: 1;
}

.userDashboard .switchWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 6px 20px;
}

.userDashboard fieldset input {
  color: #000;
  /* background-color: rgba(212, 220, 255, 0.1); */
  border-color: rgba(212, 220, 255, 0);
}
.userDashboard fieldset input:focus {
  /* background-color: rgba(212, 220, 255, 0.1); */
}

.userDashboard .errorInput {
  border-color: #dd1754;
  box-shadow: 0 0 20px #dd1752d2;
}
.userDashboard .logMsg {
  font-size: 0.889rem;
}

.userDashboard .msgModal .modal {
  min-height: 0;
  min-width: 0;
  color: #fff;
  background: none;
}
.userDashboard .msgModal .overlay {
  cursor: default;
}
.userDashboard .msgModal .modal p {
  font-size: 0.833rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.userDashboard .msgModal .modal:before {
  display: none;
}
.userDashboard .msgModal .modal i {
  font-size: 3rem;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;
  background-color: rgb(0, 175, 108);
  border-radius: 120px;
  margin-bottom: 20px;
}

.userDashboard .msgModal {
  animation: fadeOut 0.3s ease-out 2s forwards;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.referral-code {
  position: relative;
  margin-bottom: 10px;
}
.referral-code input[type="text"] {
  padding-right: 70px;
  margin-bottom: 0;
}
.referral-code .smallCta {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) scale(1);
  display: inline-block;
  padding: 6px 14px;
}
.referral-code .smallCta:hover {
  transform: translateY(-50%) scale(1.02);
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .userDashboard {
    padding-top: 100px;
  }
  .userDashboard .wrap {
    max-width: 100%;
  }
  .userDashboard main {
    width: 100%;
    margin: 0;
    border-radius: 0;
    min-width: 0;
    background-color: transparent;
  }
  #UserProfile .flexSpaceBetween {
    display: block;
    text-align: center;
  }
  * #UserProfile .stepWrap {
    margin-bottom: 20px;
  }
  #UserProfile .stepWrap,
  #UserProfile .twitterFeed {
    display: block;
    min-width: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  .userDashboard main fieldset {
    margin-bottom: 30px;
  }
  .userDashboard main fieldset > div {
    display: block;
    width: 100%;
    min-width: 0;
    max-width: 100%;
  }
  .userDashboard main fieldset > div:nth-of-type(2n + 2) {
    margin-left: auto;
  }
  .userDashboard main fieldset select,
  .userDashboard main fieldset .selectWrap {
    min-width: 0;
    max-width: 100%;
    display: block;
  }
  .userDashboard main .tar {
    text-align: center;
  }
}
