.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loader-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-image {
  margin-left: 9%;
}

.logo-loader {
  width: 250px;
}

.loader-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.loader-text {
  color: var(--Grey-500, #000);
  text-align: center;
  font-family: Inter;
  font-size: 22.336px;
  font-style: normal;
  font-weight: 500;
  line-height: 33.306px; /* 149.114% */
  margin-bottom: 20px;
}

.loader-bar {
  width: 80%;
  height: 5px;
  background-color: #dcdcdc;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.loader-bar::before {
  content: "";
  position: absolute;
  width: 15%;
  height: 100%;
  background-color: purple;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    left: 0;
  }
  50% {
    left: 85%;
  }
  100% {
    left: 0;
  }
}
