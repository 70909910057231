.wrapL {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog-layout {
  display: flex;
  gap: 20px; /* Espace entre les éléments */
  justify-content: center;
  margin-bottom: 30px;
}

.blog-card-large {
  /* flex: 2; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.blog-card-small {
  flex: 1;
  max-width: 60%;
  margin-bottom: 20px;
}

.blog-column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Espace entre les articles dans la colonne */
}

/* Image grande pour le premier article */
.blog-image-large {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Images plus petites pour les autres articles */
.blog-image-small {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.blog-titleHome {
  font-family: "Gilroy";
  color: #101828;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 10px;
}

.blog-excerpt {
  font-family: "DM Sans";
  font-size: 16px;
  color: #555;
}

.right .blog-link {
  font-family: "DM Sans";
  color: #f00;
}

/* --- Responsive Design for Mobile Devices --- */

/* Pour les écrans de 768px ou moins */
@media (max-width: 768px) {
  .blog-layout {
    flex-direction: column; /* Affiche les articles les uns sous les autres */
    gap: 20px; /* Espace entre les articles */
  }

  .blog-card-large {
    margin-bottom: 20px; /* Ajoute un espace sous l'article principal */
  }

  .blog-card-small {
    max-width: 100%; /* S'assure que les petits articles prennent toute la largeur */
  }

  .blog-image-large {
    height: 200px; /* Ajuste la hauteur de l'image principale pour le mobile */
  }

  .blog-image-small {
    height: 150px; /* Ajuste la hauteur des petites images */
  }

  .blog-titleHome {
    font-size: 20px; /* Réduit la taille des titres pour mobile */
  }

  .blog-excerpt {
    font-size: 14px; /* Réduit la taille du texte de l'extrait pour mobile */
  }
}

/* Pour les très petits écrans de 480px ou moins */
@media (max-width: 480px) {
  .blog-titleHome {
    font-size: 18px; /* Réduit encore la taille des titres */
  }

  .blog-excerpt {
    font-size: 12px; /* Réduit encore la taille du texte de l'extrait */
  }

  .blog-image-large {
    height: 180px; /* Réduit la hauteur de l'image principale */
  }

  .blog-image-small {
    height: 130px; /* Réduit la hauteur des petites images */
  }
}
