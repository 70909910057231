.boutonLead {
  border-radius: 46px;
  background: var(--Gray-100, #f2f2f2);
  display: flex;
  width: 102px;
  height: 36px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.textBouton {
  color: var(--grey-500-black, #000);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
}

.icon {
  color: var(--grey-500-black, #000);
}

.textName {
  color: var(--Color-Text-text-dark-on-light, #0f1419);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.MentionContent {
  color: var(--grey-500-black, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
}

.LeadsTable {
  max-width: 95%;
  margin: auto; /* Centrer le tableau horizontalement */
  margin-left: 20px;
  margin-top: 10px;
}

.ant-table {
  width: 100%; /* Assurez-vous que le tableau utilise toute la largeur disponible */
}

.exportButton {
  display: flex;
  width: 206px;
  height: 36px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 46px;
  background: var(--Gray-400, #353535) !important;
}

.textButtonExport {
  color: var(--Gray-0, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.contentWrapper {
  color: black;
}

.highlightedKeyword {
  background-color: yellow;
  border: none;
}

.leadDrawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ant-table-wrapper {
  width: 96%;
}

.modalLeadp {
  color: var(--grey-500-black, #000);
  text-align: center;

  /* Body */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
}

.maybelater {
  color: var(--Gray-400, var(--Grey-400, #353535));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.292px; /* 167.857% */
  cursor: pointer;
}

.socialColor {
  color: var(--Tokens, #9e00ff);
  font-family: "Mochiy Pop One";
  font-size: 23.333px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
}

.modalLeadInbox {
  color: var(--grey-500-black, #000);
  text-align: center;
  font-family: "Mochiy Pop One";
  font-size: 23.333px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px; /* 167.143% */
}
