.comingsoon {
  position: relative;
  color: #000000 !important;
  text-align: center !important;
  font-family: "Gilroy", sans-serif !important;
  font-size: 2.2vw !important;
  line-height: 1.2 !important;
  font-style: normal !important;
  font-weight: 800 !important;
  margin-top: 15px;
}
