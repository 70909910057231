.BasicMain {
  margin-top: 200px;
}

.BasicMain ul li {
  margin-bottom: 10px;
  /* list-style-type: disc; */
  margin-left: 20px;
}

.BasicMain h4 {
  margin-bottom: 10px;
}

.BasicMain .textPart {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.modal {
  position: relative;
  background: white;
  border-radius: 5px;
  padding: 20px;
  width: 80%;
  max-width: 700px;
  height: auto;
  max-height: 99%;
  box-sizing: border-box;
  overflow-y: auto;
}

#journalistPage .modal {
  position: absolute;
  z-index: 99;
  width: auto;
  padding: auto;
}

#journalistPage .close {
  padding: 0px;
  margin-right: 0px;
}

#journalistPage .cta {
  margin-left: 0px;
  margin-top: 10px;
}

.modal h2 {
  padding-left: 0px !important;
}

.modal h4 {
  margin-bottom: 20px;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 550px;
}

.close {
  position: absolute;
  right: 0;
  padding: 10px;
  margin-right: 10px;
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .BasicMain {
    margin-top: 120px;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 550px;
  }
}
