
.userDashboard nav {
    width: 18%;
    display: inline-block;
    vertical-align: top;
    position:absolute;
    margin-bottom: 20px;
    background-color: #FFF;
    height: 100%;
    padding: 20px;
    color:#9AA1AB;
    border-right: 1px solid #ECECEC;
  }
  
  .userDashboard nav li {
    position: relative;
    padding: 0px 10px 0px 10px;
  }
  .userDashboard nav li a {
    display: block;
    padding: 15px 0;
    font-weight: 600;
    font-size: 0.833rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .userDashboard nav li.disabled a {
    cursor: default;
    color: rgba(212,220,255,0.3);
  }
  .userDashboard nav li.disabled:after {
    content:"Comming Soon";
    position: absolute;
    display: inline-block;
    color: #fff;
    bottom: 4px;left: 27px;
    font-weight: 600;
    font-size: 0.633rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(212,220,255,0.1);
  }
  .userDashboard nav li:not(.disabled) a:hover {
    cursor: pointer;
  }
  .userDashboard nav li i {
    margin-right: 10px;
    font-size: 1rem;
    display: inline-block;
  }

  .logo {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
  }
  
.catNav {
  padding-top:30px;
  padding-bottom:15px;
  text-transform:uppercase;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color:#9AA1AB;
}

.copywriteFooter {
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.copywriteFooter p {
  font-size: 12px;
}

.teamData {
  margin-bottom: 30px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 20px;
  display: flex;
  cursor:pointer;
  align-items: center; /* Aligne les éléments verticalement au centre */
}

.teamData h4 {
  font-size: 16px;
  color: #0E1219;
}

.teamImage {
  flex: 1; /* Prend 1/3 de l'espace disponible */
  display: flex;
  justify-content: center; /* Centre l'image horizontalement */
  align-items: center; /* Centre l'image verticalement */
}

.teamData img {
  max-width: 100%;
  border-radius: 10px;
}

.teamInfo {
  flex: 2; /* Prend 2/3 de l'espace disponible */
  display: flex;
  flex-direction: column; /* Organise les éléments verticalement */
  justify-content: center; /* Centre les éléments verticalement */
  padding-left: 20px; /* Espacement entre l'image et le texte */
}

.teamInfo p {
  margin-top: 5px;
  font-size: 12px;
}

.teamArrow {
  flex: 0.5; /* Prend 0.5/3 de l'espace disponible */
  display: flex;
  color:#000000;
  justify-content: center; /* Centre l'icône horizontalement */
  align-items: center; /* Centre l'icône verticalement */
  font-size: 12px; /* Taille de l'icône */
}

.customDropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 200px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}

.footerLeft {
  background-color: #FAFAFA;
  padding: 20px;
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
}

.footerLeft h4 {
  font-size: 16px;
  color:#0E1219;
}

.footerLeft p {
  margin-top: 10px;
  font-size: 14px;
  color:#9AA1AB;
}


.active-link {
  background-color: #F5BFC9;
  color:#282828;
  padding: 0px 10px 0px 10px;
  border-radius:10px;
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
    .userDashboard nav {
      width: 90%;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .userDashboard nav ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .userDashboard nav li {
      display: inline-block;
    }
    .userDashboard nav li a {
      display: inline-block;
      padding: 10px;
      border: none;
    }
    .userDashboard nav li i {
      margin-right: 0;
      font-size: 1.333rem;
    }
    .userDashboard nav li a > span {
      display: none;
    }
    .userDashboard nav li.disabled:after {
        display: none;
    }
  }