/* LOAD GOOGLE FONTS */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;700;800;900&display=swap");

/* LOAD FONT AWESOME */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");

/* LOAD GOOGLE FONTS PLUS JAKARTA SANS */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* LOAD GOOGLE FONTS INTER */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* LOAD GOOGLE FONTS Mochiy Pop One */
@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap");

/* LOAD GOOGLE FONTS */
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700&family=Exo+2:ital,wght@0,100;0,300;0,400;0,600;1,100;1,300;1,400;1,600&display=swap");

/* LOAD DM FONT */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/* LOAD FONT AWESOME */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

/* html, body, #root, #root > div, .globalContainer {
  min-height: 100vh;
  height: 100%;
} */

/* Gilroy Black */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Gilroy Black Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Gilroy Bold */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Gilroy Bold Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Gilroy ExtraBold */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Gilroy ExtraBold Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Gilroy Heavy */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Gilroy Heavy Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Gilroy Light */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Gilroy Light Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Gilroy Medium */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Gilroy Medium Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Gilroy Regular */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Gilroy Regular Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Gilroy SemiBold */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Gilroy SemiBold Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Gilroy Thin */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Gilroy Thin Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Gilroy UltraLight */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Gilroy UltraLight Italic */
@font-face {
  font-family: "Gilroy";
  src: url("../public/fonts/Gilroy-UltraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

body {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  /* background-color: #263d55; */
  background-color: #fafafa;
  color: #ffffff;
  overflow-x: hidden;
  cursor: default;
}

.black {
  color: #000;
}

/* MODAL TALLY THINGS */
.tally-popup:after {
  content: "";
  background: #fff;
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  width: calc(100% - 15px);
  height: 30px;
}

.tally-powered {
  display: none;
}

/* #overlay {
  position: fixed;
  bottom: 0px;
  right: 50px;
  width: 194px;
  height: 200px;
  background-color: #fafafa;
  z-index: 1000;
} */

/* 
TITLES STYLES
 */

h1,
h2 {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  line-height: 1.1;
  /* text-transform: uppercase; */
}

h1 {
  font-size: 5rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  position: relative;
  color: #101828;
  font-family: "Gilroy";
}

h3 {
  color: #101828;
  font-family: "Gilroy";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  line-height: normal;
}

h4 {
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 600;
}

a,
button {
  cursor: pointer;
}

/* 
GLOBAL CONTAINERS
 */
.wrap {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.wrapL {
  max-width: 80%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  z-index: 1;
}

/* #HowItWorks, #infoWhatIsIt, #Price {
  background: url('/./public/grid.png') center center;
  background-size: 80%;
} */

.globalContainer {
  background-color: #fff;
  color: #000000;
}

.smallWrap {
  max-width: 1024px;
  padding: 0 30px;
}

.wrapConnect {
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 20px;
}

/* 
BUTTONS STYLES
 */
.ctaLink {
  font-weight: 800;
  display: inline-block;
  cursor: pointer;
}
.ctaLink:hover {
  text-decoration: underline;
}

.ctaLinkSmall {
  font-weight: 800;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}

.ctaMore {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #393953;
  background: #393953;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221, 23, 84, 0);
  margin-right: 30px;
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}

.right {
  text-align: right;
}

.soon {
  position: relative;
  overflow: hidden;
}

.soon::after {
  content: "Coming soon";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(28 28 43 / 62%);
  backdrop-filter: blur(5px);
  color: #ffffff;
  font-size: 2em;
  text-align: center;
  z-index: 1;
}

.soon * {
  position: relative;
  z-index: 0;
}

.ctaWhite {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5d5a88;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #fff;
  background: #fff;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221, 23, 84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}
.ctaWhiteTall {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  text-align: center;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  color: #5d5a88;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #fff;
  background: #fff;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221, 23, 84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}
.ctaGreenTall {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  text-align: center;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  color: #fff;
  border-radius: 10px 10px 10px 10px;
  background: #41ca41;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221, 23, 84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}

.newPastille {
  background: #eed3ff;
  padding: 4px 5px;
  border-radius: 23px;
  color: #9e00ff;
  font-size: 13px;
  font-weight: 560;
  text-transform: uppercase;
  margin-left: 10px;
}

.soonPastilleHome {
  background: #eed3ff;
  padding: 5px 10px;
  border-radius: 23px;
  color: #9e00ff;
  font-size: 10px;
  font-weight: 560;
  margin-left: 30px;
  text-transform: uppercase;
}

.newSoonPastille {
  position: absolute;
  top: 10px; /* aligne l'élément en haut du conteneur */
  right: 10px; /* aligne l'élément à droite du conteneur */
  background: #eed3ff;
  padding: 4px 5px;
  border-radius: 23px;
  color: #9e00ff;
  font-size: 10px;
  font-weight: 560;
  text-transform: uppercase;
  /* margin-left: 10px; */
}

.soonPastille {
  background: #eefff0;
  padding: 4px 5px;
  border-radius: 5px;
  color: #32d67e;
  font-size: 13px;
  font-weight: 560;
  text-transform: uppercase;
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}

.resultsLoaded {
  background-color: #54c774 !important; /* Change the background color when results are loaded */
}

.cta {
  display: inline-block;
  padding: 5px 20px;
  vertical-align: bottom;
  font-weight: 600;
  font-size: 0.833rem;
  color: #000;
  border-radius: 46px;
  cursor: pointer;
  background: #fff;
  background-size: 200%;
  /* border: 2px solid #000; */
  background-position: -120%;
  box-shadow: 0 0 20px rgba(241, 12, 203, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
  z-index: 5;
  position: absolute; /* Position absolue par rapport à son parent relatif */
  right: 0; /* Aligné à droite, à l'intérieur de la div inputWrapper */
  top: 50%; /* Centré verticalement */
  transform: translateY(-50%); /* Décalage pour centrer le bouton */
  margin-right: 10px;
}

.ctaSearch {
  display: inline-block;
  padding: 5px 20px;
  vertical-align: bottom;
  font-weight: 600;
  font-size: 0.833rem;
  color: #000;
  border-radius: 46px;
  cursor: pointer;
  background: #fff;
  background-size: 200%;
  border: 2px solid #000;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(241, 12, 203, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
  z-index: 5;
  position: absolute; /* Position absolue par rapport à son parent relatif */
  right: 0; /* Aligné à droite, à l'intérieur de la div inputWrapper */
  top: 50%; /* Centré verticalement */
  transform: translateY(-50%); /* Décalage pour centrer le bouton */
  margin-right: 10px;
}

/* .cta i {
  margin-left: 10px;
} */

.cta:hover {
  background-position: -200%;
  box-shadow: 0 0 30px rgba(209, 93, 255, 0.6);
  transition: background-position 0.2s ease-in, box-shadow 0.2s linear 0.1s;
}

.ctaResumeMention {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
  background: var(--Grey-100, #f2f2f2);
  border-radius: 46px;
  color: #000;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  width: 100%;
  transform: scale(1);
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 146.875% */
}

.ctaResumeMention:hover {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
  background: var(--Grey-100, #f2f2f2);
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  width: 100%;
  transform: scale(1);
  color: #3a3a3a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 146.875% */
}

.soon-link {
  color: #999;
  cursor: not-allowed;
  pointer-events: none;
  text-decoration: none;
}

.soon-link:after {
  background-color: #f7a0a0;
  border-radius: 10px;
  color: #fff;
  content: attr(data-soon-text);
  font-size: 12px;
  left: 10;
  margin-left: 5px;
  margin-top: 5px;
  padding: 5px;
}

.ctaPrimaryBlack {
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  /* vertical-align: middle; */
  background: #353535;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
}

.ctaPrimaryHome {
  display: inline-block;
  cursor: pointer;
  padding: 0px 14px;
  text-align: center;
  border-radius: 46px;
  border: 2px solid #000;
  transition: all 0.1s ease-out;
  /* margin-bottom: 10px; */
  transform: scale(1);
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  text-transform: none !important;
}

.left {
  text-align: left;
}

.ctaFullHome {
  display: inline-block;
  cursor: pointer;
  /* padding: 0px 10px; */
  text-align: center;
  border-radius: 46px;
  background: #101828;
  transform: scale(1);
  color: #fff;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  padding: 8px, 25px, 8px, 25px;
}

.ButtonActionWaitlist {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  border-radius: 46px;
  background: #101828;
  transform: scale(1);
  color: #fff;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  padding: 8px 25px 8px 25px;
}

.ctaPrimaryWhite {
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  vertical-align: middle;
  background: #f2f2f2;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  color: #000;
}

.ctaSecondary {
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid var(--gray-500, #000);
  background: #fff;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  margin-top: 10px;
  transform: scale(1);
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  width: 100%;
}

.ctaThird {
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  margin-top: 10px;
  transform: scale(1);
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
}

.ctaBase {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  vertical-align: middle;
  background: var(--Grey-100, #f2f2f2);
  border-radius: 46px;
  color: #000;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

.CtaOnboardingv2 {
  display: flex;
  width: 119px;
  height: 36px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: auto;
  color: #fff;
  border-radius: 46px;
  background: var(--Grey-500, #000);
}

.CtaOnboardingv2Back {
  display: flex;
  height: 36px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #fff;
  border-radius: 46px;
  background: var(--Grey-500, #000);
}

.CtaOnboarding {
  display: inline-block;
  cursor: pointer;
  padding: 10px 80px;
  vertical-align: middle;
  font-weight: 600;
  height: 50px;
  font-size: 0.833rem;
  letter-spacing: 1px;
  margin-left: 10px;
  text-transform: uppercase;
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  transition: all 0.1s ease-out;
  transform: scale(1);
}

.CtaOnboardingBack {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 600;
  height: 50px;
  font-size: 0.833rem;
  letter-spacing: 1px;
  margin-left: 10px;
  text-transform: uppercase;
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  transition: all 0.1s ease-out;
  transform: scale(1);
}

.smallCta {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  margin-left: 10px;
  text-transform: uppercase;
  background-color: #000;
  border-radius: 8px;
  color: #fff;
  transition: all 0.1s ease-out;
  transform: scale(1);
}

.smallCta:hover {
  transform: scale(1.02);
}

.smallCta.unFilled {
  background-color: transparent;
  color: #d4dcff;
}

.smallCta.dark {
  border: 2px solid #111117;
  background-color: #111117;
  color: #d4dcff;
}

.smallCta.red {
  border: 2px solid #6966ff;
  background-color: #6966ff;
  color: #d4dcff;
  transition: all 0.1s ease-out;
}

.smallCta.dark.unFilled {
  background-color: transparent;
  color: #111117;
}

.fbCta {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  margin-left: 10px;
  text-transform: uppercase;
  background-color: #1877f2;
  border-radius: 8px;
  color: #fff;
  transition: all 0.1s ease-out;
  transform: scale(1);
}

.premiumFeature {
  border-radius: 23px;
  background: #eed3ff;
  display: flex;
  width: 87px;
  height: 24px;
  padding: 5px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #9e00ff;
  font-size: 13px;
}

/* 
  MARGIN & CENTERING CLASSES
*/

.p {
  font-family: "DM Sans";
  font-size: 18px;
}

.tac p {
  margin-bottom: 50px;
}

.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}

.vac {
  position: relative;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.vacHome {
  position: relative;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.vacL {
  position: relative;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
}

.wrapS {
  max-width: 20%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
}

.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px !important;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb50 {
  margin-bottom: 50px;
}
.bold {
  font-weight: 600;
}
strong {
  font-weight: 800;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newPastille {
  border-radius: 5px;
  font-size: 13px;
  font-weight: 560;
  padding: 8px 10px;
  vertical-align: middle;
  text-transform: uppercase;
}

/* PREVENT USER SELECTION */
.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* DISABLED ELEMENTS */
.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.notDoneAgain {
  cursor: not-allowed;
}

.selectWrap {
  position: relative;
  display: inline-block;
}

select {
  padding: 14px 0;
  border-radius: 10px;
  background-color: #fff;
  padding-left: 20px;
  font-size: 16px;
  text-align: left;
  width: 100%;
  color: #111117;
}

select option {
  padding: 10px 20px;
  color: #111117;
  font-size: 16px;
  text-align: left;
  background-color: #d4dcff;
}
.selectWrap:after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 48%;
  transform: rotate(45deg) translateY(-50%);
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  color: #111117;
  pointer-events: none;
  opacity: 0.5;
}

.selectWrap:hover:after {
  opacity: 1;
}

/* 1024px RESPONSIVE */

@media screen and (max-width: 1024px) {
  body {
    font-size: 16px;
  }
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  .tac h2 {
    text-align: left;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 30px;
  }
  .wrap {
    max-width: 100%;
  }

  #overlay {
    display: none;
  }

  .wrapS {
    max-width: 80%;
  }

  .wrapL {
    max-width: 90%;
    margin: 0 auto;
    text-align: left;
    position: relative;
    z-index: 1;
  }

  .newPastille {
    display: block;
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 560;
    padding: 8px 10px;
    text-transform: uppercase;
  }

  .ButtonActionWaitlist {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    border-radius: 46px;
    background: #101828;
    transform: scale(1);
    color: #fff;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.5px;
    padding: 8px 10px 8px 10px;
  }
}
