#globalFooter {
  background: #ffffff;
  padding: 60px 0 0 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  color: #101828;
  flex-wrap: wrap; /* Permet l'empilement en mobile */
}

.footer-section {
  flex: 1;
  padding: 20px;
  text-align: left;
  min-width: 200px; /* Assure une taille minimale sur les grands écrans */
}

.footerLogo {
  margin-bottom: 20px;
}

.footer-description {
  color: #101828;
  font-size: 1rem;
  margin-bottom: 20px;
  width: 80%;
}

.social-icons {
  display: flex;
  gap: 30px;
}

.social-icons a {
  color: #101828;
  font-size: 1.5rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #101828;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
}

.footer-section h4 {
  color: #101828;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.newsletter-signup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.newsletter-signup input {
  padding: 10px !important;
  border: 1px solid #101828 !important;
  border-radius: 50px !important;
  flex: 1 !important;
  height: 50px !important;
}

.newsletter-signup button {
  background-color: #101828;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.subFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #101828;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-top: 1px solid #e0e0e0;
}

.footer-left {
  flex: 1;
  text-align: left;
}

.footer-left p {
  color: #101828;
  font-family: "DM Sans";
  font-size: 14px;
}

.footer-right {
  flex: 1;
  text-align: right;
}

.footer-right a {
  color: #000;
  text-decoration: none;
  margin-left: 10px;
}

.footer-right a:hover {
  text-decoration: underline;
}

/* Version mobile */
@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Empile les sections en mobile */
    align-items: center;
  }

  .footer-section {
    padding: 20px 0; /* Réduit les marges intérieures */
    text-align: left;
    width: 100%; /* Assure que chaque section prend toute la largeur */
  }

  .subFooter {
    flex-direction: column;
    text-align: center;
  }

  .footer-left,
  .footer-right {
    text-align: center;
    margin: 5px 0;
  }

  .social-icons {
    justify-content: center;
  }

  .newsletter-signup {
    flex-direction: column; /* Empile le champ de texte et le bouton */
    width: 100%; /* S'assure que le champ prend toute la largeur */
  }

  .newsletter-signup input {
    width: 90%; /* Assure une bonne taille du champ de saisie */
  }

  .newsletter-signup button {
    width: 90%; /* Assure une bonne taille du bouton */
  }
}
