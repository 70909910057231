/* OLD  */
nav#globalHeader {
  position: fixed;
  /* position: relative; */
  top: 0px;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 97;
  backdrop-filter: blur(0);
  transition: all 0.3s ease-out;
  /* background-color: #fef3f2; */
}

#globalHeader.white-header .menu li a {
  color: #fff; /* Change la couleur des liens en blanc */
}

/* nav#globalHeader { */
/* position: fixed;
  border-radius: 10px;
  width: 100%;
  height: 70px;
  z-index: 97;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: space-between; */
/* } */

/* nav#globalHeader.shrink {
  backdrop-filter: blur(1px);
  background-color: white;
} */

nav#globalHeader a {
  display: inline-block;
  padding: 10px;
  color: black;
}
nav#globalHeader a.logo {
  padding-left: 0;
  margin-top: 30px;
}

.buttonHeader {
  padding: 100px;
}

.navWrap > ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

/* Supposant que vous avez deux ul, vous pourriez spécifier le dernier avec :last-child */
.navWrap > ul:last-child {
  margin-left: auto; /* Cela pousse le dernier ul vers la droite */
}

/* Assurez-vous que les liens dans le ul de droite sont alignés correctement */
.navWrap > ul:last-child > li {
  /* Ajoutez des styles pour les éléments de liste si nécessaire */
}

.phVign {
  display: flex !important;
}

.guideDetails {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

.selectCurrency {
  width: 40% !important;
}

nav#globalHeader .navWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  padding: 0; /* Remove padding */
}

nav#globalHeader ul li {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  line-height: 24px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
}

nav#globalHeader .mobile-only {
  display: none;
}

nav#globalHeader ul li.social.first:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  width: 1px;
  margin-right: 30px;
  background: rgba(255, 255, 255, 0.4);
}

nav#globalHeader ul li.social:nth-last-child(1) i,
nav#globalHeader ul li.social:nth-last-child(2) i {
  margin-left: 20px;
}

nav#globalHeader ul li.social a {
  padding: 10px 5px;
}
nav#globalHeader ul li.social a span {
  display: none;
}
nav#globalHeader ul li.social:not(:last-of-type) {
  margin-left: 10px;
}

nav#globalHeader ul li a i {
  margin-left: 10px;
}

nav#globalHeader ul li:not(:last-of-type) {
  margin-left: 10px;
}

nav#globalHeader .logoimg {
  max-height: 50px;
  display: inline-block;
}

nav#globalHeader .logoimg .cls-1 {
  fill: #fff;
}
nav#globalHeader .logoimg .cls-2 {
  fill: #e01462;
}

/* Quand on est login  */

nav#globalHeaderLog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 97;
  backdrop-filter: blur(0);
  transition: all 0.3s ease-out;
  background-color: white;
  /* border-bottom: 1px solid #e7e7e7; */
}

nav#globalHeaderLog a {
  display: inline-block;
  padding: 10px;
  color: black;
}
nav#globalHeaderLog a.logo {
  padding-left: 0;
  margin-top: 40px;
}

nav#globalHeaderLog .navWrap {
  width: 95%;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
nav#globalHeaderLog ul li {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.833rem;
}

nav#globalHeaderLog ul li.social.first:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  width: 1px;
  margin-right: 30px;
  background: rgba(255, 255, 255, 0.4);
}

nav#globalHeaderLog ul li.social:nth-last-child(1) i,
nav#globalHeaderLog ul li.social:nth-last-child(2) i {
  margin-left: 20px;
}

nav#globalHeaderLog ul li.social a {
  padding: 10px 5px;
}
nav#globalHeaderLog ul li.social a span {
  display: none;
}
nav#globalHeaderLog ul li.social:not(:last-of-type) {
  margin-right: 10px;
}

nav#globalHeaderLog ul li a i {
  margin-right: 10px;
}

nav#globalHeaderLog ul li:not(:last-of-type) {
  margin-right: 10px;
}

nav#globalHeaderLog .logoimg {
  max-height: 50px;
  display: inline-block;
}

nav#globalHeaderLog .logoimg .cls-1 {
  fill: #fff;
}
nav#globalHeaderLog .logoimg .cls-2 {
  fill: #e01462;
}

.searchBar {
  background-color: #f2f2f2;
  border-radius: 30px;
  padding: 10px 80px 10px 30px;
  color: #666666;
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.modalSettings .modalContent {
  display: flex;
  padding: 0px;
}

.modalSettings .leftColumn {
  width: 30%;
  background-color: #fcfcfc;
  border-radius: 15px 0px 0px 15px;
  padding: 20px;
  border-right: 1px dotted #e0e0e0;
}

.apiKeyInput {
  height: 35px !important;
}

.testConnexion {
  font-size: 13px;
  cursor: pointer;
}

.leftColumn h4 {
  color: #000;
  font-family: Mochiy Pop One;
  font-size: 23.333px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.583px;
  margin-bottom: 20px;
}

.modalSettings .settingItem {
  padding: 15px 10px;
}

.modalSettings .settingIcon {
  color: #b0b0b0;
}

.modalSettings .settingTextActive {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 146.875% */
}

.modalSettings .settingIconActive {
  color: var(--Tokens, #9e00ff);
}

/* .imgSettingModal {
  padding-top: 30px;
  position: relative;
  margin-left: 10px;
} */

.imgSettingModal {
  padding-top: 30px;
  position: absolute;
  margin-left: 10px;
  bottom: 0px;
}

.columnsContainer {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
}

.whatsapp {
  border-radius: 10px;
  width: 63px;
  /* height: 63px; */
}

/* Image : 20% de la largeur */
.columnsContainer > li:first-child {
  flex: 0 0 10%; /* flex-grow, flex-shrink, flex-basis */
}

/* Nom du réseau : 60% de la largeur (100% - 20% - 20%) */
.columnsContainer > li:nth-child(2) {
  flex: 0 0 65%;
}

/* Bouton "Connecté" : 20% de la largeur */
.columnsContainer > li:last-child {
  flex: 0 0 30%;
}

.columnsContainer > li {
  margin-right: 10px; /* espace entre les colonnes, vous pouvez ajuster selon vos besoins */
}

.nameSocial {
  color: #000;

  /* H4 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 167.857% */
}

.membersList ul {
  list-style-type: none; /* Supprimer les puces de la liste */
  padding: 0;
}

.membersList li {
  display: flex; /* Faire de chaque élément de la liste un conteneur flex */
  align-items: center; /* Centrer les éléments verticalement */
  margin-bottom: 10px; /* Espace entre chaque élément de la liste */
  padding: 5px;
}

.imgMemberTeam {
  flex: 1;
  max-width: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 43px;
}

#announce {
  position: fixed;
  top: 0;
  width: 100%;
  background: #000;
  height: 40px;
  z-index: 999;
  text-align: center;
}

#announce .announceText {
  color: var(--grey-0-white, #fff);
  text-align: center;
  font-family: Mochiy Pop One;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  vertical-align: -webkit-baseline-middle;
}

#announce .promoCode {
  color: var(--Highlight, #ffd600);
  font-family: Mochiy Pop One;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.tableHeader > div,
li > div {
  display: inline-block; /* Affiche les divs côte à côte */
  vertical-align: top; /* Alignement vertical en haut */
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 195.833% */
}

.userColumn {
  width: 40%; /* Adaptez la largeur selon vos besoins */
}

.emailColumn,
.browserColumn {
  width: 18%; /* Adaptez la largeur selon vos besoins */
}

.roleColumn {
  width: 20%; /* Adaptez la largeur selon vos besoins */
}

.ctaActionSettings {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  padding: 5px 10px;
  vertical-align: middle;
  background: var(--Grey-100, #f2f2f2);
  border-radius: 46px;
  color: #000;
  transition: all 0.1s ease-out;
  transform: scale(1);
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  text-transform: uppercase;
}

.membersList select.ctaActionSettings {
  width: auto !important;
}

.borderBottom {
  border-bottom: 1px solid #d4d4d4;
}

.emailMemberTeam {
  display: block;
}

.inviteFriendModal .ant-modal-title {
  color: #000;

  /* H3 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

.inviteFriendModal label {
  color: var(--gray-500, #000);

  /* Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 195.833% */
}

.inviteFriendModal input {
  border-radius: 0px 10px 10px 10px;
  background: var(--gray-100, #f2f2f2);
  width: 100%;
  padding: 8px 16px;
}

.inviteFriendModal img {
  margin-top: 20px;
  margin-left: auto;
}

.keywordConfirmationModal {
  height: 30%;
}

.keywordConfirmationModal .ctaPrimaryWhite {
  padding: 0px 20px;
}

.keywordConfirmationModal .modalContentContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Modifié pour une meilleure compatibilité */
}

.keywordConfirmationModal .modalImageContainer {
  width: 15%;
  /* Assurez-vous que l'image s'adapte bien à cette div */
  display: flex;
  align-items: center;
  justify-content: center;
}

.keywordConfirmationModal .modalImageContainer img {
  width: 60%; /* ou une autre valeur en fonction de votre design */
  height: auto; /* pour maintenir l'aspect ratio de l'image */
}

.keywordConfirmationModal .modalTextContainer {
  width: 80%;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
}

.textTest {
  /* flex: 1; */
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 195.833% */
  letter-spacing: 3px;
  text-transform: uppercase;
}

.iconTrash {
  flex: 0.5;
  margin-left: 30px;
  color: var(--Grey-200, #b0b0b0);
}

.nameAndEmailContainer {
  display: flex;
  flex-direction: column; /* Empile les éléments verticalement */
  margin-left: 10px; /* Espace à gauche pour séparer de l'image */
  flex: 3; /* Prend 40% de l'espace total */
}

.nameMemberTeam {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 167.857% */
}

.emailMemberTeam {
  color: var(--Tokens, #9e00ff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.5px;
}

.modalSettings.ant-modal .ant-modal-content {
  padding: 0 !important;
}

.modalSettings .ant-btn-default {
  border: none;
  box-shadow: none;
  height: auto;
}

.modalSettings .ant-modal-footer {
  padding: 10px;
  border-top: 1px solid #f2f2f2;
  margin-top: 0px;
}

.modalSettings .rightContentAPI {
  width: 80%;
  padding: 20px 20px 0px 50px;
  display: flex;
  flex-direction: column;
}

.modalSettings .rightContent {
  width: 80%;
  padding: 20px 20px 0px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pour que les boutons restent en bas */
}

.modalSettings .rightContentWithout {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pour que les boutons restent en bas */
}

.tableHeader {
  background: var(--gray-background, #fafafa);
  border-radius: 3px;
  padding: 5px;
}

.profileSection {
  display: flex;
  align-items: center;
}

.fileUpload {
  display: none;
}

.vignetteConnect {
  width: 14px;
  height: 14px;
  fill: var(--Success, #90c814);
}

.urlConnectedNotif {
  color: var(--gray-300, var(--Grey-300, #666));
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  display: block;
  max-width: 290px;
  overflow: hidden; /* Empêche le contenu de déborder */
  text-overflow: ellipsis; /* Ajoute des points de suspension si le contenu déborde */
  white-space: nowrap;
}

.modalDiscord .input {
  border-radius: 0px 10px 10px 10px;
  background: var(--Grey-100, #f2f2f2);
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  display: flex;
  height: 50px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.modalDiscord .ant-btn-primary {
  background-color: #9e01fe !important;
}

.urlConnectedNotif::before {
  content: ""; /* Important pour afficher le pseudo-élément */
  display: inline-block; /* Affichage en ligne avec le texte */
  width: 10px; /* Taille du rond */
  height: 10px; /* Taille du rond */
  background-color: #90c814; /* Couleur du rond */
  border-radius: 50%; /* Rend le carré un cercle */
  margin-right: 5px; /* Espace entre le rond et le texte */
}

.modalSettings .input {
  border-radius: 0px 10px 10px 10px;
  background: var(--Grey-100, #f2f2f2);
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 146.875% */
  display: flex;
  height: 50px;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.modalSettings .userImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  /* border: 5px solid var(--Tokens, #9e00ff); */
}

.uploadLabel {
  background-color: #eee;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.modalSettings .nameSection {
  margin-top: 20px;
}

.nameSection label {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

.actionButtons {
  /* margin-top: 20px; */
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 10px;
  border-top: 1px solid var(--Grey-100, #f2f2f2);
  padding-top: 10px;
}

.modalSettings .ctaBase {
  padding: 5px 30px;
}

.cancelButton {
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

.saveButton {
  background-color: #4caf50;
  color: white;
}

.keywordFilterNav {
  display: flex;
  min-width: auto;
  height: 40px;
  padding: 0px 13px;
  font-weight: 500;
  justify-content: flex-start;
  align-items: center;
  border-radius: 46px;
  background: var(--Grey-100, #f2f2f2);
  color: var(--Grey-500, #000);
  /* padding: 10px 10px 10px 10px; */
  height: 40px;
}

.keywordFilterNav > :first-child {
  margin-right: auto; /* pousse tout autre élément vers la droite, colle ceci à gauche */
}

.keywordFilterNav > :nth-child(2) {
  margin-left: 20px; /* ajoute une marge à gauche du deuxième élément enfant */
}

.keywordFilterNav .keyword {
  font-weight: bold;
  margin-left: 10px;
}

.menuUserOpen {
  position: relative; /* Ceci est essentiel pour que .userDropdown soit positionné par rapport à .menuUserOpen */
}

.menu {
  display: flex;
  justify-content: center; /* Center the menu items */
  flex: 2; /* Menu should take up more space in the middle */
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  margin: 0 15px; /* Add spacing between menu items */
}

.userDropdown {
  background: #fff;
  width: 300px;
  position: absolute;
  top: 120%;
  right: 0;
  border: 2px solid var(--Grey-100, #f2f2f2);
  z-index: 10;
  border-radius: 15px;
  box-shadow: 2px 6px 12px -9px rgba(0, 0, 0, 0.03);
  display: none;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.5px; /* 167.857% */
  text-transform: none;
}

.userDropdown {
  display: block;
}

.darkmodeSwitch {
  margin-left: auto;
  cursor: not-allowed;
}

/* .darkmodeSwitch.ant-switch-checked {
  background-color: #9e00ff;
}
.darkmodeSwitch.ant-switch :hover {
  background-color: #9e00ff;
} */

.userHeader {
  display: flex;
  align-items: center;
  padding: 20px 20px 10px 20px;
}

.userImage {
  /* width: 20%; */
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Rend l'image ronde */
  margin-right: 10px; /* Ajoute un espace entre l'image et le texte */
  vertical-align: middle;
}

.userImageHome {
  /* width: 20%; */
  width: 32px;
  height: 32px;
  border-radius: 50%; /* Rend l'image ronde */
  margin-right: 10px; /* Ajoute un espace entre l'image et le texte */
  vertical-align: middle;
}

.freeTrial {
  color: #9e00ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-right: 20px;
  vertical-align: middle;
}

.nolink {
  text-decoration: none;
}

.ErrorLinkedinAlert {
  background-color: #ff8d8d;
  text-align: center;
  font-size: 15px;
  vertical-align: bottom;
  padding-top: 25px;
  color: #000;
}

.platformsContainer {
  display: flex;
  flex-wrap: wrap;
}

.platformItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100px;
  height: 100px;
  text-align: center;
}

.platformItemSelected {
  border-color: #007bff;
  background-color: #e6f7ff;
}

.platformIcon {
  font-size: 24px;
  margin-bottom: 5px;
}

.platformIcon[style] {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.platform-card .platform-icon {
  width: 90%; /* assurez-vous que cela remplisse le container */
  height: auto; /* gardez le ratio d'aspect original */
  max-width: 50px; /* Limite la taille maximale de l'icône */
  max-height: 50px; /* Limite la hauteur maximale */
  text-align: center;
}

.linkBuy {
  font-size: 14px;
}

.leftPosition {
  position: relative;
  left: -30px;
}

.bookDemo {
  color: #000000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
}

.keywordMenu {
  position: absolute;
  z-index: 1000;
  right: 70px;
  min-width: 330px;
  border-radius: 15px;
  border: 1px solid var(--Grey-100, #f2f2f2);
  background: #fff;
  margin-top: 10px;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 1);
}

.keywordTrash {
  position: absolute;
  right: 0;
}

.subTitleModal {
  display: block;
  margin-top: 10px;
  color: var(--Gray-500, var(--grey-500-black, #000));

  /* Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 195.833% */
}

.editTrash {
  position: absolute;
  right: 0;
  margin-right: 40px;
}

.keywordMenu div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.deleteKeywordModal .ant-btn-primary {
  background-color: #f2f2f2 !important;
  color: #000;
  border-radius: 46px;
  box-shadow: none !important;
}

.deleteKeywordModal .ant-btn-default {
  background-color: none !important;
  color: #353535;
  border-radius: 46px;
  box-shadow: none !important;
  border: none !important;
}

.deleteKeywordModal .ant-btn-default:hover {
  background-color: none !important;
  color: #3b3b3b;
  border-radius: 46px;
  box-shadow: none !important;
  border: none !important;
}

.deleteKeywordModal .ant-btn-primary:hover {
  background-color: #f2f2f2 !important;
  color: #3b3b3b;
  border-radius: 46px;
  box-shadow: none !important;
}

.keywordList {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.keywordListActive {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.keywordMenu div:hover {
  background-color: #f5f5f5;
}

.modalNewKeyword h2 {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  padding-left: 0px;
  margin-bottom: 20px;
}

.modalNewKeyword p {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px; /* 139.286% */
}

.modalNewKeyword .inputKeyword {
  margin-top: 10px;
  border-radius: 0px 10px 10px 10px;
  background: var(--Grey-100, #f2f2f2);
  display: flex;
  width: 100%;
  padding: 8px 32px 8px 16px;
  align-items: center;
  align-self: stretch;
}

.modalNewKeyword img {
  margin-top: 40px;
  margin-left: 20px;
}

.modalNewKeyword .actionButtons {
  margin-top: 0px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.5px; /* 195.833% */
  max-width: 83%;
  overflow-x: hidden;
}

.userName {
  color: var(--Grey-500, #000);
  margin: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.5px; /* 167.857% */
}

.userEmail {
  margin: 0;
  color: var(--Tokens, #9e00ff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.5px; /* 195.833% */
}

.dottedLine {
  border: none;
  border-top: 1px dotted #e0e0e0;
  margin: 10px 0; /* Ajoute un espace au-dessus et en dessous de la ligne */
}

.dottedLineWithoutSpace {
  border: none;
  border-top: 1px dotted #e0e0e0;
}

.settingHeader {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 8px;
}

.settingHeader a {
  padding: 0px !important;
}

.settingText {
  color: var(--Grey-300, #666);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 146.875% */
}

.settingItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  padding: 5px 10px; /* Ajustez le padding si nécessaire */
}

.settingIcon {
  width: 15%;
  margin-right: 10px; /* Ajoute un espace entre l'icône et le texte */
  color: var(--Tokens, #9e00ff);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 23.5px; /* 180.769% */
}

.primaryColor {
  color: #9e00ff;
}

.teamSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 173px;
  height: 40px;
  padding: 10px 10px 10px 10px;
  border-radius: 55px;
  background: #9e00ff;
}

.chevron {
  margin-left: 10px;
  vertical-align: super;
  color: #000;
}

.notificationNavbar {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 23.5px; /* 146.875% */
  /* margin-right: 20px; */
}

.teamImageColumn {
  display: flex;
  width: 33px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50%;
  background: #fff;
}

.teamSelect h4 {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.582px; /* 113.18% */
}

.teamSelect p {
  color: #fff;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.582px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.flex {
  display: flex;
}

.notif {
  position: absolute;
  top: 0;
  margin-left: 10px;
  background-color: red;
  color: white;
  width: 5px;
  height: 5px;
  padding: 8px;
  font-weight: 500;
  z-index: 999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
}

.notifMenu {
  position: absolute;
  top: 100%; /* Positionnez le menu juste en dessous de l'icône de la cloche */
  right: 10%; /* Alignez le menu avec le côté gauche de l'icône de la cloche */
  background-color: white; /* Couleur de fond du menu */
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  width: 300px; /* Largeur du menu */
  z-index: 1000; /* Placez le menu au-dessus des autres éléments */
  margin-top: -10px;
}

.notifContent {
  color: #000;
  padding: 10px;
  /* border-bottom: 1px solid #ececec; */
}

/* Retirez la bordure du dernier élément du menu */
.notifContent:last-child {
  border-bottom: none;
}

.notifContent p {
  font-size: 12px;
  text-transform: capitalize;
}

.avatar {
  border-radius: 20px;
  width: 30px;
  vertical-align: middle;
  margin-right: 10px;
}

nav#globalHeader .mobileMenu {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #000000;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 20px;
  cursor: pointer;
  display: none;
}

nav#globalHeader .mobileMenu span {
  display: inline-block;
  background: #fff;
  width: 40%;
  height: 3px;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

nav#globalHeader .mobileMenu span:after,
nav#globalHeader .mobileMenu span:before {
  content: "";
  display: inline-block;
  background: #fff;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  position: absolute;
  left: 0;
  -webkit-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}
nav#globalHeader .mobileMenu span:before {
  top: -8px;
}
nav#globalHeader .mobileMenu span:after {
  top: 8px;
}

.mobileMenu {
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 10;
}

.mobileMenu span {
  width: 100%;
  height: 3px;
  background-color: black;
  display: block;
  transition: all 0.3s ease;
}

.mobileNav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 20;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.mobileNav.open {
  display: flex;
  opacity: 1;
}

.mobileNav ul {
  list-style: none;
  padding: 0;
}

.mobileNav ul li {
  margin: 20px 0;
}

.mobileNav ul li a {
  font-size: 24px;
  text-decoration: none;
  color: black;
}

@media (max-width: 768px) {
  .ant-row {
    flex-direction: column;
  }

  .modal-image-container {
    display: none; /* Cache l'image sur mobile */
  }

  .modal-content {
    width: 100%;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.modal-image-container {
  text-align: center;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.closeMenu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 36px;
  cursor: pointer;
}

.ctaButtonMobile {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: black;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
}

/* OPEN & CLOSE STATES */

/* NAVIGATION MOBILE */
@media screen and (max-width: 1024px) {
  nav#globalHeader .mobileMenu {
    display: block; /* Afficher le menu mobile en dessous de 1024px */
  }

  nav#globalHeader a.logo {
    padding-left: 10px;
    margin-top: 15px;
  }

  nav#globalHeader ul {
    display: none;
    position: fixed;
    top: 90px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    transform: scale(0);
    opacity: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  nav#globalHeader.navOpen ul {
    display: flex;
    transform: scale(1);
    opacity: 1;
  }

  nav#globalHeader ul li {
    color: #fff;
    font-size: 24px;
    margin-bottom: 30px;
  }

  nav#globalHeader .logoimg {
    max-height: 40px;
  }
}

/* NAV OPEN STYLES */

nav#globalHeader.navOpen .navWrap:before {
  transform: translateY(0);
  opacity: 1;
}

nav#globalHeader.navOpen ul {
  opacity: 1;
  transform: scale(1);
  display: block;
}

nav#globalHeader.navOpen ul li {
  transform: translateY(0);
  opacity: 1;
}

nav#globalHeader.navOpen .mobileMenu span {
  background: transparent;
}
nav#globalHeader.navOpen .mobileMenu span:before,
nav#globalHeader.navOpen .mobileMenu span:after {
  top: 0;
}
nav#globalHeader.navOpen .mobileMenu span:before {
  transform: rotate(45deg);
}
nav#globalHeader.navOpen .mobileMenu span:after {
  transform: rotate(-45deg);
}

nav#globalHeader ul li:not(:last-of-type) {
  margin-left: 0px;
}

/* Ceci est une media query pour les écrans ayant une largeur maximale de 1280 pixels, par exemple, ce qui pourrait être la résolution d'un écran 13 pouces */
@media screen and (max-width: 1520px) {
  .columnsContainer > li:nth-child(2) {
    /* Ajustez la valeur ici selon vos besoins */
    flex: 0 0 55%;
  }

  .footerPreImg {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -11px;
    margin-right: 5%;
    margin-top: -9%;
  }
}

@media (max-width: 768px) {
  .scroll-links {
    display: none !important;
  }
}
