.article-grid-section {
  margin: 20px 0;
}

.articles-gridBlog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Espace entre le contenu et le lien */
  height: 100%; /* Assure que toutes les cartes ont la même hauteur */
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.blog-excerpt {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.pagination-controls button {
  border: none;
  background-color: transparent;
  color: #6c757d;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  transition: color 0.3s ease;
}

.pagination-controls button:hover {
  color: #f04e23;
}

.pagination-controls button.active {
  background-color: #ffeae5;
  color: #f04e23;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}

.pagination-controls button.disabled {
  cursor: not-allowed;
  color: #e0e0e0;
}

.pagination-controls button:disabled {
  cursor: not-allowed;
  color: #e0e0e0;
}

.pagination-controls .pagination-ellipses {
  pointer-events: none;
  color: #6c757d;
}

/* --- Responsive Adjustments for Mobile Devices --- */

/* Pour les écrans de taille inférieure ou égale à 768px (tablettes et mobiles) */
@media (max-width: 768px) {
  .articles-gridBlog {
    grid-template-columns: 1fr; /* Une seule colonne pour les petits écrans */
    gap: 20px;
  }

  .blog-title {
    font-size: 16px; /* Ajuste la taille des titres sur mobile */
  }

  .blog-excerpt {
    font-size: 13px; /* Ajuste la taille du texte de l'extrait */
  }

  .pagination-controls button {
    font-size: 14px; /* Réduit légèrement la taille des boutons de pagination */
    padding: 8px;
  }

  .pagination-controls button.active {
    width: 35px; /* Réduit la taille du bouton actif */
    height: 35px;
  }

  .right {
    justify-content: flex-start; /* Aligne les éléments à gauche pour mobile */
  }
}

/* Pour les très petits écrans, en dessous de 480px */
@media (max-width: 480px) {
  .blog-title {
    font-size: 14px; /* Réduit encore un peu la taille des titres */
  }

  .blog-excerpt {
    font-size: 12px; /* Réduit la taille du texte de l'extrait */
  }

  .pagination-controls button {
    font-size: 12px; /* Taille plus petite pour les boutons de pagination */
    padding: 6px;
  }

  .pagination-controls button.active {
    width: 30px; /* Taille plus petite pour le bouton actif */
    height: 30px;
  }
}
