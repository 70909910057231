.modalContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;left: 0;
  z-index: 99;
}

.overlay {
  height: 100vh;
  width: 100vw;
  background: rgba(5, 5, 5, 0.6);
  position: fixed;
  top: 0;left: 0;
  z-index: 0;
  opacity: 0;
  animation: fade 0.1s linear forwards;
  cursor: url('/./public/close.png'), auto;
}

@keyframes fade {
  from{
    opacity: 0;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
  }
  to{
    opacity: 1;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
}


.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  min-height: 200px;
  min-width: 400px;
  display: inline-block;
  padding: 40px 60px;
  color: #101012;
  z-index: 1;
  opacity: 0;
  animation: modalEntry 0.3s ease-out forwards;
  background-color: #d4dcff;
  border-radius: 8px;
}
.modal:before {
  content:"";
  position: absolute;
  z-index: 0;
  top: 0;left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  border-radius: 8px;
  /* background: url('/./public/modalpattern.png') no-repeat top center; */
  background-size: contain;
  /* box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%); */
  pointer-events: none;
}

@keyframes modalEntry {
  0%{
    transform: translateY(-50%, -45%);
    opacity: 0;
  }
  100%{
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}


.modal > div {
  position: relative;
  z-index: 2;
}

.modalHeader {
  width: 100%;
  text-align:center;
  top: 10px;
  padding:10px;
  position: relative !important;
}

.modal .modal-close-button {
  position: absolute;
  right: 3px;
  top: 3px;
  display: inline-block;
  font-weight: 500;
  height: 40px;width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  /* cursor: pointer; */
}
.modal .closeBtn {
  color: #111117;
  display: inline-block;
  height: 40px;width: 40px;
  border-radius: 50%;
  -webkit-transition: 0.1s all ease-out;
  -o-transition: 0.1s all ease-out;
  transition: 0.1s all ease-out;
}
.modal .closeBtn:hover {
  color: #111117;
}
.modal .closeBtn:before {
  content:"";
  position: absolute;
  z-index: -1;
  top: 0;left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  border: 2px solid rgba(0,0,0,0.05);
  background: rgba(0,0,0,0.05);
  -webkit-transition: 0.1s all ease-out;
  -o-transition: 0.1s all ease-out;
  transition: 0.1s all ease-out;
}
.modal .closeBtn:hover:before {
  border-color: rgba(0,0,0,0.2);
}

.modal h4 {
  font-size: 3rem;
}

.modal input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}


.errorMsg {
  font-size: 14px;
  color: #e50f5c;
  margin: 20px 0;
}


legend {
  display: block;
  margin-bottom: 20px;
}




.modalContainer .googleConnectButton button > span {
  color: #111117 !important;
}

.modalContent .specialLink{
  font-size: 0.833rem;
  text-transform: uppercase;
}

.modalContent {
  padding:0px 0px 0px 20px;
}
