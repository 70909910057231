.langModal {
    position: fixed;
    z-index: 100;
    min-height: 0;
    min-width: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    display: inline-block;
    padding: 20px 40px;
    color: #101012;
    background: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    opacity: 0;
    animation: langModalEntry 0.3s ease-out forwards;
}

.langModal legend {
    font-size: 0.833rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.langModal .selectWrap {
    display: block;
}

.langModal select,
.langModal option {
    font-size: 0.833rem;
    padding: 10px;
}




@keyframes langModalEntry {
    0%{
        transform: translateY(-50%, -100%);
        opacity: 0;
    }
    100%{
        transform: translate(-50%, 0);
        opacity: 1;
    }
}


