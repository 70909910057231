.menu-container {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  /* padding: 10px; */
  padding: 20px 60px 0px 25px;
  width: 100%;
}

.disabledIcons {
  color: rgba(176, 176, 176, 1);
  cursor: not-allowed;
}

.notActive {
  color: rgba(176, 176, 176, 1);
}

.nbrResults {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #9e00ff;
  color: white;
  padding: 5px;
  margin-left: 10px;
}

.nbrResultsActif {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  color: #9e00ff;
  padding: 5px;
  margin-left: 10px;
}

.sourceToggle.premiumOnly {
  color: #999; /* Gris */
  pointer-events: none; /* Désactive les interactions */
}

.premiumLabel {
  margin-left: 10px;
  font-size: 0.8em;
  color: #f00; /* Rouge */
}

.active-linkMenu .menu-text {
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.filterButtonContainer {
  position: relative;
}

.titleMenuColumn {
  color: #666;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.filtersColumn {
  border-right: 1px solid #f3f3f3;
}

.dropdownMenu {
  position: absolute;
  transform: translateX(-50%);
  width: 450px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  z-index: 1;
  margin-top: 75px;
  margin-left: 145px;
  border-radius: 15px;
  box-shadow: 2px 6px 12px -9px rgba(0, 0, 0, 0.03);
}

.buttonActiveMenu {
  border-radius: 44px;
  background: var(--Token, #9e00ff);
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.buttonNoActiveMenu {
  border-radius: 44px;
  background: var(--Grey-100, #f2f2f2);
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.select-deselect-all-container {
  /* padding-left: 15px; */
  text-align: center;
  color: #9e00ff;
}

.select-deselect-all-container .titleMenuColumn {
  color: #9e00ff;
}

.buttonNoActiveMenuNew {
  border-radius: 44px;
  background: var(--Grey-100, #f2f2f2);
  border: 2px solid #9e00ff;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.buttonNoActiveMenu:hover {
  background: #c5c5c5;
}

.dropdownMenuNoRead {
  position: absolute;
  transform: translateX(-97%);
  width: 270px;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  z-index: 1;
  right: 0;
  margin-top: 75px;
  margin-left: 25px;
  border-radius: 15px;
  box-shadow: 2px 6px 12px -9px rgba(0, 0, 0, 0.03);
}

.filterDuration .ant-select-selector {
  background-color: transparent !important;
  border: 0px !important;
}

.sourceToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.sourceToggle span {
  font-size: 14px;
}

.langueToggle {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.langueToggle img {
  margin-right: 8px; /* Espacement entre le drapeau et le nom de la langue */
}

.languageName {
  flex-grow: 1; /* Permet au nom de la langue de prendre tout l'espace disponible */
  font-size: 14px;
}

.menuUpdateMentions {
  display: flex; /* Active flexbox */
  justify-content: space-between; /* Changé à 'space-between' pour séparer le contenu et le menu */
  align-items: center; /* Centre verticalement */
  height: 100%; /* Hauteur totale si vous voulez centrer verticalement dans la page */
  margin-top: 20px;
  padding: 5px 48px 5px 25px;
  border-radius: 12px;
  width: 99%;
  position: relative; /* Pour le positionnement absolu du menu déroulant */
}

.updateMention {
  display: flex;
  justify-content: space-between; /* Assure la répartition de l'espace */
  align-items: center;
  background: #f6e6ff;
  border-radius: 12px;
  padding: 5px;
  width: 100%;
}

.updateMentionDashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6e6ff;
  border-radius: 12px;
  padding: 5px;
  width: 100%;
}

.updateMentionsContainer {
  flex-grow: 1; /* Permet au conteneur de texte de prendre tout l'espace disponible */
  text-align: center; /* Garantit que le texte reste centré */
}

.updateMentions {
  color: #9e00ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  display: inline-block; /* Pour permettre la sousligne d'être plus proche du texte */
}

.menuUpdateProblems {
  display: flex; /* Active flexbox */
  justify-content: space-between; /* Changé à 'space-between' pour séparer le contenu et le menu */
  align-items: center; /* Centre verticalement */
  height: 100%; /* Hauteur totale si vous voulez centrer verticalement dans la page */
  padding: 5px 48px 5px 25px;
  border-radius: 12px;
  width: 99%;
  position: relative; /* Pour le positionnement absolu du menu déroulant */
}

/* .newFeatureTag {
  font-size: 14px;
  margin-left: 5px;
  color: red;
} */

.NewTag {
  position: relative;
  display: inline-block;
}

.arrow-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(333deg);
}

.arrow-container img {
  order: 2; /* Mettre l'image en bas */
  width: 50px;
}

.arrow-text {
  font-size: 13px;
  color: #9e00ff;
}

.arrow-container .arrow-text {
  order: 1; /* Mettre le texte en haut */
  position: absolute;
  top: -22px; /* Ajustez cette valeur selon vos besoins */
  right: -40px;
  transform: rotate(26deg);
}

.updateProblem {
  display: flex;
  justify-content: space-between; /* Assure la répartition de l'espace */
  align-items: center;
  background: #f2f2f2;
  border-radius: 12px;
  padding: 5px;
  width: 100%;
}

.updateProblemDashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6e6ff;
  border-radius: 12px;
  padding: 5px;
  width: 100%;
}

.updateProblemsContainer {
  flex-grow: 1; /* Permet au conteneur de texte de prendre tout l'espace disponible */
  text-align: center; /* Garantit que le texte reste centré */
}

.updateProblems {
  color: #353535;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  display: inline-block; /* Pour permettre la sousligne d'être plus proche du texte */
}

.menuKebab {
  cursor: pointer;
  margin-left: auto;
  padding-right: 10px;
}

.dropdownMenuReadMark {
  position: absolute;
  top: 100%;
  right: 40px;
  box-shadow: 2px 14px 9px 0px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  border: 1px solid var(--Gray-100, #f2f2f2);
  background: var(--Gray-0, #fff);
  overflow: hidden;
  z-index: 999;
}

.dropdownItemReadMark {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  display: block;
}

.dropdownItemReadMark:hover {
  background-color: #f1f1f1;
}

.sousligne {
  text-decoration-line: underline;
}

.sourceToggle:hover {
  background-color: #f5f5f5;
}

.filterDuration {
  border-radius: 46px;
  background: var(--Grey-100, #f2f2f2);
  display: flex;
  /* width: 230px; */
  width: auto;
  /* height: 35px; */
  padding: 0px 13px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  font-size: 13px !important;
}

.filter {
  background: var(--Grey-100, #f2f2f2);
  padding: 5px 20px 5px 20px;
  border-radius: 46px;
  display: flex;
  width: 114px;
  /* height: 44px; */
  padding: 0px 13px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.filter:hover {
  background: #c5c5c5;
}

.mentionsNonLuButton {
  background: var(--Grey-100, #f2f2f2);
  padding: 5px 20px 5px 20px;
  border-radius: 46px;
  display: flex;
  /* width: 230px; */
  height: 35px;
  padding: 0px 13px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
}

.active-linkMenu {
  font-weight: 600;
}

.grp {
  display: flex;
  height: 44px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 71px;
  background: var(--Grey-100, #f2f2f2);
}

.ctaPrimaryBlackMenu {
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
  height: 44px;
  background: #353535;
  border-radius: 46px;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
}

.small {
  font-size: 10px;
  vertical-align: middle;
}

.middle-elements {
  display: flex;
  justify-content: center; /* Centrage horizontal des éléments du milieu */
  gap: 10px; /* Espace entre les éléments du milieu */
}

.linkMenu {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 44.082px;
}

.filtresButton {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 44.082px;
}

.icons {
  display: flex;
  gap: 15px; /* Espace entre les icônes */
}

/* Pour le mobile  */

@media (max-width: 768px) {
  .buttonActiveMenu,
  .buttonNoActiveMenu {
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    padding: 0px 10px;
  }

  .downloadRapport {
    display: none;
  }
}
