
/* GOOGLE CONNECT BUTTON */

.facebookConnectButton {
    display: inline-block;
    margin-left: 30px;
}
.facebookConnectButton button {
    border-radius: 50px !important;
    background-color: #1877F2 !important;
    padding: 3px 10px !important;
    border: none !important;
    box-shadow: none !important;
    vertical-align: middle;
  }
  .facebookConnectButton button > div {
    margin-right: 0 !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 0 4px 0 10px !important;
    height: 18px !important;
    width: 18px !important;
    padding: 0 !important;
  }
  .facebookConnectButton button > span {
    color: #fff;
  }

  .ctaGoogle {
    padding: 0px !important;
  }





/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .facebookConnectButton {
    margin-left:0px;
  }
}