.mentiondata {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  padding: 20px;
  /* min-height: 100%; */
  border: 1px solid #ececec;
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
}

.mentiondataPieChart {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0.13%,
      #faf3ff 99.87%
    ),
    linear-gradient(270deg, #f1dbff 0%, #eae2ff 100%);
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  padding: 20px;
  /* min-height: 100%; */
  /* border: 1px solid #ececec; */
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
}

.mentiondataPieChart,
.mentiondataNegative,
.mentiondataPositive,
.mentiondataNeutral h5 {
  color: var(--grey-500-black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

.textSentiment {
  margin-left: 10px;
  vertical-align: super;
}

.numberSentimentBad {
  color: #641321;
  font-family: Mochiy Pop One;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

.numberSentimentNeutrale {
  color: #60551f;
  font-family: Mochiy Pop One;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

.numberSentimentGood {
  color: #354a08;
  font-family: Mochiy Pop One;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

.latestUpdateBad {
  color: #f6b3be;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.latestUpdateNeutrale {
  color: #e7db98;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.latestUpdateGood {
  color: #bce75f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.5px; /* 195.833% */
}

.mentiondataNegative {
  background: #fff2f4;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  padding: 20px;
  /* min-height: 100%; */
  /* border: 1px solid #ececec; */
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
}

.mentiondataNeutral {
  background: #fef9e1;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  padding: 20px;
  /* min-height: 100%; */
  /* border: 1px solid #ececec; */
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
}

.mentiondataPositive {
  background: #f0f7e2;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  padding: 20px;
  /* min-height: 100%; */
  /* border: 1px solid #ececec; */
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
}

.mentiondataAds {
  background-color: #f6e6ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ajouté pour aligner les enfants en haut et en bas */
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  padding: 10px;
  /* min-height: 100%; */
  border: 1px solid #ececec;
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
}

.didyouknow {
  color: var(--Tokens, #9e00ff);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  margin-bottom: 10px;
}

.buttonAdsBuska {
  width: 100%;
  border-radius: 6px;
  background: #9e00ff;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.imgMedal {
  width: 50%;
  max-width: 130px;
  margin-bottom: 10px;
}

.textButtonAds {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 167.857% */
}

.imgAndButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Assurez-vous que le conteneur prend toute la largeur */
}

.ctaResumeMention a {
  color: #000;
}

.mentions-container {
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); /* Crée quatre colonnes de taille égale */
  gap: 1rem; /* Espacement entre les divs */
  width: 100%;
}

.noMentions {
  height: 333px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.noMentions .avatar {
  width: 32px;
  height: 32px;
}

.noMentions .traitShort {
  border-radius: 17.5px;
  background: #f0f0f0;
  width: 91px;
  height: 13px;
}

.noMentions .empty {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  flex-basis: calc(25% - 16px);
  /* max-width: calc(25% - 16px); */
  box-sizing: border-box;
  min-height: 100%;
  background-color: #f5f5f5;
  border-radius: 12px;
}

.noMentions .avatarAndTraits {
  display: flex;
  align-items: center; /* Alignement vertical au centre pour avatar et traitLong */
}

.noMentions .traitLong {
  border-radius: 17.5px;
  background: #f0f0f0;
  width: 50%; /* ou la largeur souhaitée pour traitLong */
  height: 13px; /* ou la hauteur souhaitée pour traitLong */
  margin-left: 5px; /* espace entre l'avatar et traitLong */
}

.noMentions .traitMoyen {
  border-radius: 17.5px;
  background: #f0f0f0;
  width: 70px; /* ou la largeur souhaitée pour traitMoyen */
  height: 13px;
  margin-top: 5px; /* espace entre traitShort et traitMoyen */
}

.noMentions .traitMoyenLong {
  border-radius: 17.5px;
  background: #f0f0f0;
  width: 100px; /* ou la largeur souhaitée pour traitMoyenLong */
  height: 13px;
  margin-top: 5px; /* espace entre traitMoyen et traitMoyenLong */
}

.WellDone {
  color: #353535;
  font-family: Mochiy Pop One;
  font-size: 23px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
}

.mentiondataPie {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  color: black;
  position: relative;
  /* min-height: 100%; */
  border: 1px solid #ececec;
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
}

.piedata {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px;
  width: 100%;
  color: black;
  position: relative;
  /* min-height: 100%; */
  border: 1px solid #ececec;
  border-radius: 12px;
  flex-grow: 1;
  box-shadow: 0px 4px 18px -9px rgba(0, 0, 0, 0);
  min-height: 100%; /* Assurez-vous que le conteneur de la vignette prend toute la hauteur disponible */
  justify-content: space-between; /* Pour pousser le contenu vers le haut et le bouton vers le bas */
  height: 420px; /* Hauteur légèrement supérieure à celle du PieChart */
  margin: auto; /* Pour centrer si nécessaire */
  overflow: hidden; /* Pour masquer tout contenu débordant */
}

.alignVign:hover .mentiondata {
  border: 1px solid #fafafa;
}

.highlighted {
  border: 3px solid #000;
}

.favicon {
  width: 45px;
  height: 45px;
}

.alignChart:hover .piedata::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0px solid #ececec;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  pointer-events: none; /* Assurez-vous que les événements de la souris ne sont pas capturés par ce pseudo-élément */
}

/* .mentiondata :hover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0px solid #ececec;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  pointer-events: none; 
}

.mentiondata :hover {
  border: 0px solid #ececec;
} */

.imgRank {
  display: flex;
  justify-content: space-between; /* Optionnel: ajoute de l'espace entre les éléments */
  align-items: center; /* Optionnel: centre les éléments verticalement */
}

.mentionContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Reste à space-between */
  height: 100%;
}

.post-contentModal {
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5.47041px 24.61682px -12.30841px rgba(0, 0, 0, 0);
  border: 1.368px solid #ececec;
}

.post-header {
  display: flex;
  align-items: center; /* Centre les éléments verticalement */
  margin-bottom: 10px;
}

.icon-container {
  font-size: 20px;
  margin-right: 10px;
}

.poweredShare img {
  margin-top: 50px;
}

.poweredShare {
  text-align: center;
}

.adContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 340px; /* Largeur maximale de 340px */
  z-index: 1000;
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); */
  /* padding: 20px; */
  border: 1px solid var(--Tokens, #9e00ff);
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
}

.closeAd {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding-right: 10px;
}

.buttonBottom {
  border-radius: 6px;
  background: var(--Grey-100, #f2f2f2);
  padding: 10px;
  width: 48%;
  text-align: center;
}

.buttonBottom:hover {
  background: var(--Grey-300, #cccccc); /* Couleur plus sombre au survol */
}

.poweredShare p {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  margin-top: 10px;
}

.post-name {
  display: block; /* Affiche le nom du réseau sur sa propre ligne */
  font-weight: bold;
}

.custom-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

.color-selector {
  display: flex;
  gap: 10px; /* Espacement entre chaque option de couleur */
}

.color-option {
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Rend les options de couleur circulaires */
  cursor: pointer; /* Change le curseur en main quand vous survolez une option de couleur */
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.export-button,
.cancel-button {
  display: flex;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.export-button {
  border-radius: 46px;
  background: var(--Grey-100, #f2f2f2);
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 146.875% */
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-content {
  background-color: none;
}

.exportBlack {
  background: linear-gradient(135deg, #f05f57 0%, #360940 100%) !important;
}

.exportGrey {
  background: linear-gradient(135deg, #abdcff 0%, #0396ff 100%) !important;
}

.exportPurple {
  background: linear-gradient(135deg, #ff96f9 0%, #c32bac 100%) !important;
}

.exportGradiant {
  background: linear-gradient(
    299deg,
    #ffd788 0%,
    #ffbfbf 33.33%,
    #c5c3ff 66.67%,
    #23d5ab 100%
  );
}

.exportGrey .ant-modal-content {
  background: linear-gradient(135deg, #abdcff 0%, #0396ff 100%);
}

.exportPurple .ant-modal-content {
  background: linear-gradient(135deg, #ff96f9 0%, #c32bac 100%);
}

.feedModal.exportGradiant .ant-modal-content {
  background: linear-gradient(
    299deg,
    #ffd788 0%,
    #ffbfbf 33.33%,
    #c5c3ff 66.67%,
    #23d5ab 100%
  );
}

.bottomMention {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid #ebeaea; */
  padding-top: 10px;
}

.bottomMentionCentered {
  justify-content: center;
}

.mentionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 1900px; /* Définir une largeur maximale */
  margin-left: auto; /* Centrer horizontalement */
  margin-right: auto; /* Centrer horizontalement */
}

.NameSocial {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  /* max-height: 47px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limite à 2 lignes */
  -webkit-box-orient: vertical;
}

.topRightDrawer {
  display: flex;
  justify-content: flex-end; /* Pousse les éléments vers la droite */
  align-items: center; /* Centre les éléments verticalement */
}

.webkitBoxFeed {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Le texte prend 60% de l'espace */
.ml10.lineHeight {
  flex: 0 0 70%;
}

.petitRond {
  border-radius: 46px;
  background: var(--Grey-100, #f2f2f2);
  padding: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.petitRondDashboard {
  border-radius: 46px;
  background: var(--Grey-100, #f2f2f2);
  padding: 14px;
  margin-right: 10px;
  cursor: not-allowed;
}

.dateAgo {
  color: #a3a3a3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
}

.text-truncate {
  font-size: 14px;
}

.feedModal :where(.css-dev-only-do-not-override-yp8pcc).ant-select-multiple {
  width: 800px;
  margin-left: 10px;
}

.feedModal .ant-modal-mask {
  backdrop-filter: blur(10px);
}

.feedModal .ant-modal-content {
  /* border: 8px solid #fff !important; */
  background-color: transparent; /* Fond transparent */
  background: linear-gradient(
    293deg,
    #ffd788 -19.69%,
    #ffbfbf 28.72%,
    #c5c3ff 77.14%,
    #23d5ab 125.56%
  );
  padding: 60px 80px 100px !important;
}

.highlightedKeyword {
  background-color: #ffd600;
  padding: 3px;
}

.googlefeed {
  color: #184dc5;
  height: fit-content;
  max-width: 50px;
}

.googleFeed {
  max-width: 10%;
}

.preserve-newlines {
  flex: 1;
  white-space: pre-line;
  font-size: 16px;
}

.contentWrapper {
  flex: 1;
}

.feedDrawer .ant-drawer-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.feedDrawer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  color: black;
  font-size: 12px;
  border-radius: 15px;
}

.ant-drawer-content-wrapper {
  top: 15px !important;
  bottom: 15px !important;
  right: 15px !important;
}

.feedDrawer .ant-drawer-content {
  border-radius: 15px;
}

.bottomDrawer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  /* margin-top: 30px; */
  margin-bottom: -20px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-drawer .ant-drawer-mask {
  /* background: none; */
}

.tiktok-embed {
  max-width: 100%;
}

.blurredFeed {
  filter: blur(5px); /* Ajustez la valeur selon le niveau de flou souhaité */
  pointer-events: none; /* Pour empêcher les interactions avec les éléments flous */
}

.blurredFake {
  filter: blur(3px); /* Ajustez la valeur selon le niveau de flou souhaité */
  position: relative;
}

.lockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.lockIcon {
  font-size: 3rem;
  color: white;
}

.mentionContent:hover .lockOverlay {
  opacity: 1;
}

.blurred-overlay {
  /* position: absolute; */
  /* top: 765px; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background: linear-gradient(
    180deg,
    rgba(250, 250, 250, 0.4) 0%,
    #fbfbfb 68.03%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-content {
  text-align: center;
}

.centered-content {
  display: flex;
  position: absolute;
  top: 0; /* Cette propriété n'a pas d'effet ici car la div n'est pas en position absolue ou relative. */
  align-items: center;
}

.centeredDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed; /* Pour le positionner par rapport à l'écran */
  bottom: 10%; /* Pour le positionner en bas de l'écran, ajustez selon vos besoins */
  left: 50%;
  transform: translateX(-50%); /* Pour le centrer horizontalement */
  z-index: 1000; /* Pour s'assurer qu'il s'affiche au-dessus des autres éléments */
}

.leftText {
  flex: 1; /* Pour s'assurer que le texte prend tout l'espace disponible à gauche */
}

.rightImage {
  width: 100px; /* Ajustez selon la taille souhaitée de l'image */
  height: auto;
  margin-left: 20px; /* Pour ajouter un peu d'espace entre le texte et l'image */
}

.linkHeight {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px; /* 167.857% */
}

.rounded {
  border-radius: 1.5rem !important;
}

.alignEmbed {
  display: flex;
  justify-content: center;
}

.instagram-media {
  min-width: 100% !important;
}

.interest-embed {
  min-width: 100% !important;
}

.postTextContent {
  margin-bottom: 10px;
  border-bottom: 1px solid #ebeaea;
}

.analysisSentiment {
  padding: 0px 15px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-top: 1px solid #ebeaea;
  border-bottom: 1px solid #ebeaea; */
}

.analysisSentimentVignette {
  padding: 0px 15px;
  margin-top: auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
  /* border-top: 1px solid #ebeaea; */
  /* border-bottom: 1px solid #ebeaea; */
}

.analysisSentimentVignette h4 {
  font-family: Inter;
  padding: 0px !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  margin-bottom: 8px;
}

.goodNote {
  border-left: 4px solid var(--Success, #90c814);
}
.badNote {
  border-left: 4px solid var(--Success, #f00);
}
.neutNote {
  border-left: 4px solid var(--Success, #ffd600);
}
.lockNote {
  border-left: 4px solid var(--Token, #9e00ff);
}

#UserDashboard .sentimentBottom h4 {
  padding: 0px 0px;
  margin-top: 10px;
}

.sentimentBottom {
  border-top: 1px solid #ebeaea;
  margin-top: auto;
}

.lockedNote {
  color: var(--Success, #9e00ff);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  margin-left: 8px;
}

.positiveNote {
  color: var(--Success, #90c814);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  margin-left: 8px;
}

.neutraleNote {
  color: var(--Highlight, #ffd600);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  margin-left: 8px;
}

.negativeNote {
  color: var(--Error, #f00);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  margin-left: 8px;
}

.lock {
  color: var(--Highlight, #9e00ff);
  font-family: Font Awesome 6 Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 23.5px; /* 180.769% */
}

.neutrale {
  color: var(--Highlight, #ffd600);
  font-family: Font Awesome 6 Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 23.5px; /* 180.769% */
}

.bad {
  color: var(--Error, #f00);
  font-family: Font Awesome 6 Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 23.5px; /* 180.769% */
}

.good {
  color: var(--Success, #90c814);
  font-family: Font Awesome 6 Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 23.5px; /* 180.769% */
}

.analysisSentiment h4 {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.5px;
  margin-bottom: 8px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alignVign {
  display: flex;
  /* padding: 16px 24px; */
  padding: 20px 0px 15px 25px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  flex-basis: calc(25% - 16px);
  max-width: calc(25% - 16px);
  box-sizing: border-box;
  min-height: 100%; /* Assurez-vous que chaque vignette prend toute la hauteur disponible */
  position: relative; /* Cela permettra de positionner le triangle par rapport à la vignette */
}

.alignVignDate {
  display: flex;
  /* padding: 16px 24px; */
  padding: 20px 0px 15px 25px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  /* flex-basis: calc(25% - 16px);
  max-width: calc(25% - 16px); */
  box-sizing: border-box;
  min-height: 100%; /* Assurez-vous que chaque vignette prend toute la hauteur disponible */
  position: relative; /* Cela permettra de positionner le triangle par rapport à la vignette */
}

.triangle::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent #9e00ff transparent transparent;
  position: absolute;
  top: 22px;
  right: 2px;
  border-radius: 0px 5px 0px 0px;
}

.nonread {
  border: 2px solid #9e00ff !important;
}

.evaluate {
  color: #9e00ff;
  border: 1px solid #9e00ff;
}

.overlay-white {
  position: absolute; /* or absolute */
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center; /* Centrer verticalement */
  justify-content: center; /* Centrer horizontalement */
}

.comeBack {
  color: var(--Tokens, #9e00ff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
}

.centered-box {
  width: 30%;
  height: 65%;
  max-width: 600px;
  max-height: 600px;
  display: flex; /* Utilisation de flexbox pour le contenu interne */
  flex-direction: column; /* Empilement vertical des éléments */
  justify-content: center; /* Centrer le contenu verticalement dans la boîte */
  align-items: center; /* Centrer le contenu horizontalement dans la boîte */
  background-color: white;
  border-radius: 15px;
  border: 1.012px solid var(--gray-100, #f2f2f2);
  box-shadow: 2px 14px 9px 0px rgba(0, 0, 0, 0.07);
}

.centered-boxNoMentions {
  width: 30%;
  height: 80%;
  max-width: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 558px;
}

.inner-content {
  margin-bottom: 20px;
  padding: 15px;
  text-align: center; /* Centre le texte et tout élément inline ou inline-block */
}

.inner-content p {
  margin-top: 10px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px; /* 146.875% */
}

.active-link {
  cursor: pointer; /* Style du curseur pour indiquer une action cliquable */
  text-decoration: underline; /* Souligne le texte */
  color: blue; /* Couleur du texte pour indiquer qu'il est actif/cliquable */
  margin-top: 20px; /* Espacement au-dessus du lien */
}

.alignChart {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  flex-basis: calc(50% - 16px);
  max-width: calc(50% - 16px);
  box-sizing: border-box;
  min-height: 100%; /* Assurez-vous que chaque vignette prend toute la hauteur disponible */
}

.text-truncateFeed {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
  margin-bottom: 20px;
}

.whiteVignFeed {
  position: relative;
  border-radius: 10px;
  color: black;
  margin-top: 20px;
  /* width: 100%; */
  margin-right: 30px;
}

@media screen and (max-width: 1024px) {
  .alignVign {
    display: flex;
    padding: 20px 0px 15px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    box-sizing: border-box;
    min-height: 100%;
    max-width: 100%;
    flex-basis: auto;
    width: 100%;
    position: relative;
  }

  .mentionFilterDropdown {
    display: none;
  }

  .alignChart {
    display: flex;
    padding: 20px 0px 15px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    box-sizing: border-box;
    min-height: 100%;
    max-width: 100%;
    flex-basis: auto;
    width: 100%;
    position: relative;
  }

  .ml10.lineHeight {
    flex: 0 0 55%;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .ant-drawer-content-wrapper {
    top: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
  }

  .grp {
    display: none;
  }

  .filterButtonContainer {
    display: none;
  }

  .icons {
    display: none;
  }

  .menu-container {
    padding: 10px;
    grid-template-columns: auto;
  }

  .keywordFilterNav {
    min-width: 100%;
  }

  .keywordMenu {
    width: 65%;
    min-width: auto;
  }

  .filterDuration {
    display: none;
  }

  .webkitBoxFeed {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .feedModal {
    width: 100% !important;
  }

  .switchText {
    display: none;
  }

  .feedModal .ant-modal-content {
    padding: 10px !important;
  }

  .modalSettings {
    width: 100%;
  }

  .modalSettings .settingTextActive {
    display: none;
  }

  .modalSettings .settingText {
    display: none;
  }

  .modalSettings .rightContent {
    padding: 10px 10px 0px 10px;
  }

  .leftColumn h4 {
    font-size: 10px;
  }

  .pricingContainer {
    left: 0px;
  }

  #MenuSettingsItem {
    display: none;
  }

  #MenuSettingsItemdottedLine {
    display: none;
  }

  #MenuTeamItem {
    display: none;
  }

  .logoPricing {
    width: 30%;
  }

  .arrowImage {
    display: none;
  }

  .feedDrawer {
    border-radius: 0px;
  }

  #boutonDashboardMention {
    display: none;
  }

  .containerPricingPlans {
    max-width: 100%;
  }

  .containerPricingPlansUltimate {
    max-width: 100%;
  }

  .alignAll .ctaPrimaryBlack {
    margin: auto;
  }

  .imgPlanBuska {
    width: 30%;
  }

  .feedbackLogin {
    display: none;
  }

  .blurred-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(
      180deg,
      rgba(250, 250, 250, 0.4) 0%,
      #fbfbfb 68.03%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #UserDashboard h4 {
    margin-top: 10px;
  }

  .menuUpdateMentions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 20px;
    padding: 5px 5px 5px 25px;
    border-radius: 12px;
    width: 100%;
  }
}
