.whiteVign {
  position: relative;
  /* background: #fff; */
  border-radius: 10px;
  padding: 30px;
  color: black;
  margin-top: 50px;
  width: 100%;
  margin-right: 30px;
}

.whiteVignSources {
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  color: black;
  margin-top: 50px;
  width: 45%;
  margin-right: 30px;
}

.ctaPrimaryWhiteDash {
  margin-top: 20px;
  display: inline-block;
  cursor: pointer;
  padding: 15px 20px;
  text-align: center;
  vertical-align: middle;
  background: #f2f2f2;
  transition: all 0.1s ease-out;
  margin-bottom: 10px;
  transform: scale(1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.5px;
  color: #000;
  width: 95%;
  margin-left: 25px;
  max-width: 1900px;
}

.alignMid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
}

.whiteVignNews {
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  color: black;
  margin-top: 50px;
  width: 25%;
  text-align: center;
}

.whiteVignNews h5 {
  font-size: 17px;
}

.whiteVignNews p {
  font-size: 15px;
}

.whiteVignAnalysis {
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  color: black;
  margin-top: 50px;
  width: 30%;
  margin-right: 30px;
  text-align: center;
}

.filterUpdate {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-left: 20px;
  padding: 10px;
  color: #374151;
  font-weight: 500;
}

.alignFilter {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mw40 {
  max-width: 40%;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.socialVignDash {
  background-color: #fff;
  text-align: left;
  padding: 20px;
  width: 400px;
  color: black;
  height: 200px;
  margin-right: 50px;
  border: 1px solid white;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.link {
  color: #8a8a8a;
  font-size: 13px;
  text-transform: uppercase;
}

#UserDashboard h1 {
  /* margin-bottom: 60px; */
}

#UserDashboard h4 {
  padding: 0px 25px;
  margin-top: 20px;
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.01px;
}

#UserDashboard .flexSpaceBetween {
  align-items: flex-start;
  flex-wrap: wrap;
}

#UserDashboard .stepWrap,
#UserDashboard .twitterFeed {
  display: inline-block;
  vertical-align: top;
}

#UserDashboard .twitterFeed {
  display: inline-block;
  width: 40%;
  min-width: 300px;
}

#UserDashboard .stepWrap {
  width: 40%;
  min-width: 300px;
  margin-right: 15%;
}

#UserDashboard .step {
  position: relative;
  padding: 20px 0;
}

#UserDashboard .step:first-of-type h4 {
  margin-bottom: 10px;
}
#UserDashboard .step:before {
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0;
  font-size: 4rem;
  line-height: 1;
  font-weight: 600;
  opacity: 0.1;
}
#UserDashboard .step:nth-of-type(1):before {
  content: "01";
}
#UserDashboard .step:nth-of-type(2):before {
  content: "02";
}
#UserDashboard .step:nth-of-type(3):before {
  content: "03";
}
#UserDashboard .step:nth-of-type(4):before {
  content: "04";
}
#UserDashboard .step:not(:last-of-type) {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(212, 220, 255, 0.3);
}

#UserDashboard .discord i {
  font-size: 1.5rem;
  margin-right: 10px;
}

#UserDashboard .social a {
  display: inline-block;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 50px;
  margin-bottom: 10px;
}
#UserDashboard .social a:not(:last-of-type) {
  margin-right: 14px;
}
#UserDashboard .social a i {
  font-size: 0.833rem;
}

#UserDashboard .step.disabled {
  border-color: rgba(212, 220, 255, 1);
}
#UserDashboard .step.disabled:after {
  content: "Comming Soon";
  display: block;
  color: #fff;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
}

#UserDashboard .timeline-TweetList-tweet {
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 14px;
  padding: 10px;
}

#UserDashboard input {
  color: #fff;
  background-color: rgba(212, 220, 255, 0.1);
  border-color: rgba(212, 220, 255, 0);
}
#UserDashboard input:focus {
  border-color: rgba(212, 220, 255, 1);
  background-color: rgba(212, 220, 255, 0.1);
}

#UserDashboard .errorInput {
  border-color: #dd1754;
  box-shadow: 0 0 20px #dd1752d2;
}
#UserDashboard .logMsg {
  font-size: 0.889rem;
}

.userReferralCode {
  color: #dd1754;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary {
  background-color: #6866ff !important;
}

.containerInput {
  display: flex;
  justify-content: space-between;
}

.half {
  flex: 0 0 50%; /* cela prendra 48% de la largeur pour laisser un peu d'espace entre */
  box-sizing: border-box; /* pour que les padding/margin ne s'ajoutent pas à la largeur */
}

.form-grouphalf {
  margin-top: 10px; /* un petit espace entre le label et l'input */
  margin-bottom: 20px; /* un petit espace entre le label et l'input */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.piedata h5 {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.01px;
  padding: 10px 10px 0px;
}

.piedata hr {
  border: 1px solid #f2f2f2;
  margin: 10px;
}

.mentiondata h5 {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.01px;
  padding: 20px 20px 0px;
}

.mentiondata hr {
  border: 1px solid #f2f2f2;
  margin: 10px;
}

.mentiondataPie h6 {
  padding: 10px 10px 0px;
  color: var(--gray-500, #000);
  /* H1 */
  font-family: Mochiy Pop One;
  font-size: 23.333px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.583px; /* 83.929% */
}

.mentiondataPie h5 {
  color: var(--Grey-500, #000);
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.01px;
  padding: 20px 20px 0px;
}

.mentiondataPie hr {
  border: 1px solid #f2f2f2;
  margin: 10px;
}

.piedata:hover {
  border: 1px solid #fafafa;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  transition: border 0.3s ease;
}

.mentiondata:hover {
  border: 1px solid #fafafa;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  transition: border 0.3s ease;
}

.mentiondataPie:hover {
  border: 1px solid #fafafa;
  box-shadow: 2px 17px 18px -9px rgba(0, 0, 0, 0.07);
  transition: border 0.3s ease;
}

.alignAll {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.alignAll .ctaPrimaryBlack {
  margin: 0px 60px;
}

.dataWrite {
  display: inline-flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 20px;
}

.pourcentGrowth {
  color: var(--SUccess, #9cd323);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.01px;
}

/* input[type="email"],
input[type="text"],
input[type="password"] {
  width: 60%;
  padding: 10px;
  height: 56px;
  background-color: #FFF;
  border-radius: 10px;
  text-align: left;
  padding-left: 15px;
} */

/* Si vous voulez augmenter l'intensité du flou et de l'obscurcissement de l'arrière-plan */
.ant-modal-mask {
  backdrop-filter: blur(
    5px
  ); /* Cela peut ne pas être supporté par tous les navigateurs */
  background-color: rgba(0, 0, 0, 0.75); /* Plus sombre */
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  .userDashboard {
    padding-top: 100px;
  }
  .userDashboard .wrap {
    max-width: 100%;
  }
  .userDashboard main {
    width: 100%;
    margin: 0;
    border-radius: 0;
    min-width: 0;
    background-color: transparent;
  }
  #UserDashboard .flexSpaceBetween {
    display: block;
    text-align: center;
  }
  * #UserDashboard .stepWrap {
    margin-bottom: 20px;
  }
  #UserDashboard .stepWrap,
  #UserDashboard .twitterFeed {
    display: block;
    min-width: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
  #UserDashboard .social a span {
    display: none;
  }
  #UserDashboard .social a {
    font-size: 1.5rem;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    display: inline-block;
    background-color: rgba(212, 220, 255, 0.05);
    margin-bottom: 0;
  }
  #UserDashboard .social a i {
    font-size: 1.5rem;
  }
  #UserDashboard .mentiondataPie {
    display: none;
  }
}

.showNegativeMentions {
  color: #641321;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.showNeutralMentions {
  color: #60551f;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.showGoodMentions {
  color: #354a08;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.premiumNeed {
  width: 100%;
  /* filter: blur(3px); */
}

.custom-modal-free-trial-over .ant-modal-header {
  text-align: center;
}

.modalTitleFreeTrialOver {
  color: var(--grey-500-black, #000);
  text-align: center;
  font-family: Mochiy Pop One;
  font-size: 23.333px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}

.modalCenterFreeTrial {
  text-align: center;
}

.imageFreeTrial {
  padding: 30px;
  width: 100%;
}

.modalContentFreeTrialOver {
  color: var(--grey-500-black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}

.alignVign.premiumNeed .overlay-message {
  /* Style pour le message */
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Pour que le message soit au-dessus du contenu flouté */

  /* Style du texte */
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.pieChartContainer {
  margin: 0 auto;
  max-width: 270px; /* ou la largeur maximale que vous préférez */
  width: 100%;
  height: 110px; /* ou ajustez selon la hauteur souhaitée */
  position: relative; /* pour positionner absolument l'aiguille si nécessaire */
}

.sent {
  background-color: #f1fbeb;
  color: #9cc980;
  border-radius: 50%; /* Pour obtenir un cercle parfait */
  width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  display: flex; /* Pour centrer l'icône */
  align-items: center; /* Centrer l'icône verticalement */
  justify-content: center; /* Centrer l'icône horizontalement */
}

.pending {
  background-color: #f5ebfb;
  color: #c980b9;
  border-radius: 50%; /* Pour obtenir un cercle parfait */
  width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  display: flex; /* Pour centrer l'icône */
  align-items: center; /* Centrer l'icône verticalement */
  justify-content: center; /* Centrer l'icône horizontalement */
}

/* .error {
  background-color: #fbebeb;
  color: #ff0057;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.error {
  background-color: #fbebeb;
  color: #ff0057;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 10px 20px 10px 10px;
  justify-content: center;
}

.draft {
  background-color: var(--sib-color_surface-sidebar-background, #f9fafc);
  color: var(--sib-color_surface-hover-background, #c0ccda);
  border-radius: 50%; /* Pour obtenir un cercle parfait */
  width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  display: flex; /* Pour centrer l'icône */
  align-items: center; /* Centrer l'icône verticalement */
  justify-content: center; /* Centrer l'icône horizontalement */
}

.other {
  background-color: var(--sib-color_surface-sidebar-background, #f9fafc);
  color: var(--sib-color_surface-hover-background, #c0ccda);
  border-radius: 50%; /* Pour obtenir un cercle parfait */
  width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  display: flex; /* Pour centrer l'icône */
  align-items: center; /* Centrer l'icône verticalement */
  justify-content: center; /* Centrer l'icône horizontalement */
}

.scheduled {
  background-color: var(--sib-color_surface-sidebar-background, #fbebeb);
  color: var(--sib-color_surface-hover-background, #7d3a3a);
  border-radius: 50%; /* Pour obtenir un cercle parfait */
  width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
  display: flex; /* Pour centrer l'icône */
  align-items: center; /* Centrer l'icône verticalement */
  justify-content: center; /* Centrer l'icône horizontalement */
}
