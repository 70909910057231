.green {
    color:rgb(63, 134, 0);
}

.ant-card-body {
    font-size: 20px;
    text-align:center;
    text-transform:uppercase;
}

.ant-card-body h4{
    font-size: 40px;
    font-weight:bold;
    margin-bottom:10px;
    text-align:center;
    text-transform:uppercase;
}

.ant-card-body h6{
    font-size: 14px;
    font-weight:500;
    margin-bottom:10px;
    text-align:center;
}

.text16 {
    font-size:16px;
}