#AdminSideBar {
  background: #0b0b0c;
  width: 280px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: inline-block;
  vertical-align: top;
  padding-top: 30px;
}
#AdminSideBar .logo {
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
  padding-left: 30px;
}

#AdminSideBar ul {
  text-align: left;
}

#AdminSideBar li > a,
#AdminSideBar li > span {
  display: block;
  padding: 20px 30px;
  /* cursor: pointer; */
}
#AdminSideBar li i {
  margin-right: 10px;
}

#AdminSideBar li:hover {
  background: #101012;
}
