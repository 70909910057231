.center {
  text-align: center;
}

.white {
  color: white;
}

.plans-headerAPI {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.plans-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto 0;
  width: 100%;
}

.logoPricingAPI {
  margin-bottom: 25px;
  width: 35%;
}
